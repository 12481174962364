import { navigate } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Product, StyledArrowBox } from '@/components';
import { COLORS, productKinds } from '@/constants';
import { ProductKind } from '@/interfaces';

interface ProductSelectorProps {
  onProductSelect: (product: ProductKind) => void;
}

const ProductSelectorContainer = styled.div`
  margin-bottom: 40px;

  > .section-container {
    background-color: ${COLORS.INVESTMENT_FORM_BACKGROUND};
    padding: 120px 64px 0 64px;
    display: flex;

    @media (max-width: 1399px) {
      padding: 100px 64px 0 64px;
    }

    @media (max-width: 991px) {
      padding: 140px 36px 0 36px;
    }
  }

  .scroll-ref {
    position: absolute;
    top: -100px;

    @media (max-width: 991px) {
      top: -64px;
    }
  }

  .container {
    width: 100%;
    height: 100%;
    padding-left: 34px;

    @media (max-width: 991px) {
      padding-left: 0;
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
    }
  }

  .ps-header {
    width: 536px;
    max-width: 100%;

    @media (max-width: 1399px) {
      width: 475px;
    }

    @media (max-width: 991px) {
      font-weight: bold;
    }

    @media (max-width: 767px) {
      margin-bottom: 34px;
    }

    @media (max-width: 575px) {
      width: 430px;
      max-width: 100%;
    }
  }

  .ps-paragraph {
    margin-top: 34px;
    width: 536px;
    max-width: 100%;

    @media (max-width: 1399px) {
      width: 475px;
    }
  }

  .ps-products {
    margin-top: 80px;
    margin-bottom: 140px;
    display: grid;
    grid-column-gap: 33px;
    grid-template-columns: repeat(auto-fill, 122px);
    grid-row-gap: 33px;

    @media (max-width: 1399px) {
      margin-top: 65px;
      margin-bottom: 110px;
    }

    @media (max-width: 991px) {
      margin-bottom: 80px;
      grid-column-gap: 58px;
    }

    @media (max-width: 767px) {
      margin-top: 40px;
      grid-row-gap: 20px;
      justify-content: center;
    }

    @media (max-width: 374px) {
      grid-column-gap: 40px;
    }

    @media (max-width: 359px) {
      grid-column-gap: 20px;
      grid-template-columns: repeat(auto-fill, 112px);
    }

    .checkbox-selected {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .styled-arrow-box-back {
    width: 40px;
    height: 40px;
    background-color: unset;

    @media (max-width: 991px) {
      display: none;
    }

    &:hover {
      .arrow-icon {
        #arrow {
          stroke: ${COLORS.BUTTON_SECONDARY_HOVER};
        }
      }  
    }

    .arrow-icon {
      #arrow {
        stroke: ${COLORS.COLOR_SECONDARY};
      }
    }
  }
`;

const ProductSelector = ({ onProductSelect }: ProductSelectorProps): JSX.Element => {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState<ProductKind>();

  const handleOnClick = (product: ProductKind): void => {
    onProductSelect(product);
    setSelectedProduct(product);
    if (product.isAvailable) {
      setTimeout(() => {
        scrollTo('#investmentForm-scroll-ref', 'start');
      }, 200);
    } else {
      setTimeout(() => {
        scrollTo('#confirmationForm-scroll-ref', 'start');
      }, 200);
    }
  };

  const backToMain = (): void => {
    navigate('/');
  };

  return (
    <ProductSelectorContainer id="product-selector">
      <div
        className="scroll-ref"
        id="productSelector-scroll-ref"
      />
      <div className="section-container">
        <StyledArrowBox
          className="styled-arrow-box-back"
          color="secondary"
          direction="left"
          onClick={backToMain}
        />
        <div className="container">
          <h2 className="ps-header">{ t('investmentForm:choose-investment') }</h2>
          <p className="ps-paragraph">{ t('investmentForm:choose-investment-subtitle') }</p>
          <div className="ps-products">
            {
              productKinds.map((product, index) => (
                <Product
                  key={`product-${index + 1}`}
                  isSelected={selectedProduct?.value === product.value}
                  onClick={(): void => handleOnClick(product)}
                  product={product}
                />
              ))
            }
          </div>
        </div>
      </div>
    </ProductSelectorContainer>
  );
};

export default ProductSelector;
