/* eslint-disable indent */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Checkmark, ExclamationMark } from '@/assets/svgs';
import { StyledButtonWithArrow } from '@/components';
import { COLORS } from '@/constants';
import { FormButtonStatuses } from '@/types';

interface FormButtonProps {
  className?: string;
  loading?: boolean;
  onClick: () => void;
  onReset?: () => void;
  status: FormButtonStatuses;
  title?: string;
}

const FormButton = ({
  className,
  loading = false,
  onClick,
  onReset = (): void => {},
  status,
  title = '',
}: FormButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const getButtonConfiguration = (): {title: string, icon?: React.ReactNode, message?: string } => {
      switch (status) {
      case 'ERROR':
        return {
          title: t('contactForm:button.error'),
          icon: <ExclamationMark />,
          message: t('contactForm:error-message'),
        };
      case 'SUCCESS':
        return {
          title: t('contactForm:button.success'),
          icon: <Checkmark />,
          message: t('contactForm:success-message'),
        };
      default:
        return { title: title || t('contactForm:button.send') };
    }
  };

  return (
    <div className={className}>
      <StyledButtonWithArrow
        className="form-button"
        color="primary"
        direction="right"
        icon={getButtonConfiguration().icon}
        loading={loading}
        onClick={status === 'SUBMIT' ? onClick : undefined}
        title={getButtonConfiguration().title}
      />
      <div className="error-label">
        <span className="message">{ getButtonConfiguration().message }</span>
        { status === 'ERROR' && (
          <span
            className="reset"
            onClick={onReset}
          >
            { t('contactForm:try-again') }
          </span>
        ) }
      </div>
    </div>
  );
};

const StyledFormButton = styled(FormButton)`
  min-height: 82px;

  .form-button {
    .button {
      min-width: 92px;
    }

    ${({ status }): FlattenSimpleInterpolation => {
      switch (status) {
        case 'SUBMIT':
          return css`
            :hover {
              .button, .arrow-box {
                background-color: ${COLORS.FORM.BUTTON_HOVER};
              }
            }

            .button, .arrow-box {
              background-color: ${COLORS.FORM.BUTTON}; 
              color: ${COLORS.FORM.BUTTON_TEXT};
            }`;
        case 'ERROR':
          return css`
            :hover {
              .button, .arrow-box {
                background-color: ${COLORS.FORM.BUTTON_ERROR};
              }
            }

            .button, .arrow-box {
              background-color: ${COLORS.FORM.BUTTON_ERROR};
              color: ${COLORS.FORM.BUTTON_ERROR_SUCCESS_TEXT};
            }`;
        case 'SUCCESS':
          return css`
            :hover {
              .button, .arrow-box {
                background-color: ${COLORS.FORM.BUTTON_SUCCESS};
              }
            }

            .button, .arrow-box {
              background-color: ${COLORS.FORM.BUTTON_SUCCESS};
              ${COLORS.FORM.BUTTON_ERROR_SUCCESS_TEXT};
            }`;
        default:
          return css``;
      }
    }};

    .button, .arrow-box {
      #exclamation-mark {
        transform: rotate(180deg);
      }

      .arrow-icon { 
        #arrow {
          stroke: ${COLORS.FORM.BUTTON_TEXT};
        }
      }
    }
  }

  .error-label {
    line-height: 14px;
    margin-top: 8px;

    font-size: 12px;
    line-height: 1.17;
    color: ${COLORS.FORM.BUTTON};

    .message {
      color: ${COLORS.FORM.BUTTON_ERROR_SUCCESS_TEXT};
    }

    .reset {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default StyledFormButton;
