import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CookieBarButton from './Button';
import { COLORS } from '@/constants';

const ALLOW = 'true';
const DENY = 'false';

type CookieOption = typeof ALLOW | typeof DENY;

type Cookie = {
  name: string;
  cookieFunction?: () => void;
}

interface CookieBarProps {
  className?: string;
  functionalCookies?: Cookie[];
  marketingCookies?: Cookie[];
}

const CookieBar = ({ className, functionalCookies = [], marketingCookies = [] }: CookieBarProps): JSX.Element => {
  const { t } = useTranslation();
  const [showCookieBar, setShowCookieBar] = useState(false);

  const getCookieValue = (cookieName: string): CookieOption | undefined => (
    Cookies.get(cookieName) as CookieOption | undefined
  );

  const setAndRunCookies = (cookies: Cookie[], value: CookieOption): void => {
    cookies.forEach(({ name, cookieFunction }) => {
      Cookies.set(name, value, { expires: 365 });
      if (value === ALLOW && cookieFunction) {
        cookieFunction();
      }
    });
  };

  const hasAllCookies = (cookies: Cookie[]): boolean => (
    cookies.every(({ name }): boolean => !!getCookieValue(name))
  );

  const onMarketingButtonClick = (): void => {
    setAndRunCookies(marketingCookies, ALLOW);
    setAndRunCookies(functionalCookies, DENY);
    setShowCookieBar(false);
  };

  const onFunctionalButtonClick = (): void => {
    setAndRunCookies(functionalCookies, ALLOW);
    setAndRunCookies(marketingCookies, DENY);
    setShowCookieBar(false);
  };

  const onAcceptAllButtonClick = (): void => {
    setAndRunCookies(functionalCookies, ALLOW);
    setAndRunCookies(marketingCookies, ALLOW);
    setShowCookieBar(false);
  };

  useEffect(() => {
    if (!hasAllCookies(functionalCookies) || !hasAllCookies(marketingCookies)) {
      setShowCookieBar(true);
      return;
    }

    [...functionalCookies, ...marketingCookies].forEach(({ name, cookieFunction }) => {
      if (getCookieValue(name) === ALLOW) {
        if (cookieFunction) {
          cookieFunction();
        }
      }
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      { showCookieBar && (
        <div className={className}>
          <div className="content">
            <span className="description">
              <span>{ `${t('cookies:description')} `}</span>
              <Link
                className="link"
                to="/cookies-policy"
              >
                { t('cookies:link') }
              </Link>
            </span>
            <div className="buttons">
              <CookieBarButton
                color="secondary"
                onClick={onMarketingButtonClick}
              >
                { t('cookies:only-marketings') }
              </CookieBarButton>
              <CookieBarButton
                color="secondary"
                onClick={onFunctionalButtonClick}
              >
                { t('cookies:only-functional') }
              </CookieBarButton>
              <CookieBarButton
                color="primary"
                onClick={onAcceptAllButtonClick}
              >
                { t('cookies:accept-all') }
              </CookieBarButton>
            </div>
          </div>
        </div>
      ) }
    </>
  );
};

const StyledCookieBar = styled(CookieBar)`
  position: fixed;
  bottom: 80px;
  width: 100vw;
  margin: auto;
  z-index: 100;
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
    margin: 0 80px;
    background-color: ${COLORS.COOKIE_BAR_CONTENT};
    display: flex;
    justify-content: space-between;
    box-shadow: ${COLORS.SHADOWS.COOKIE_BAR};

    .description {
      color: ${COLORS.COOKIE_BAR_DESCRIPTION};
      text-align: left;
      margin: auto 36px;

      .link {
        text-decoration: underline;
        cursor: pointer;
        white-space: nowrap;
        color: ${COLORS.COOKIE_BAR_DESCRIPTION};

        :hover {
          color: ${COLORS.COOKIE_POLICY_HOVER};
        }
      }
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      min-width: 613px;
    }
  }

  @media (max-width: 1450px) {
    .content {
      flex-direction: column;
      padding: 40px 0;

      .description {
        padding-left: 0;
        justify-content: center;
      }

      .buttons {
        margin: 24px 36px 0;
        min-width: unset;

        ${CookieBarButton}:first-of-type {
          border-left: none;
        }
      }
    }
  }

  @media (max-width: 783px) {
    .content {
      margin: 0 36px;
      padding: 40px 36px;

      .description {
        display: inline;
      }

      ${CookieBarButton}:last-of-type {
        flex-grow: 1;
        margin: 16px 0 0;
        text-align: center;
      }
    }
  }

  @media (max-width: 623px) {
    bottom: 0;

    .content {
      padding: 40px 0;
      margin: 0;
      box-shadow: none;

      .buttons {
        margin: 24px 0 0;

        ${CookieBarButton}:last-of-type {
          margin: 16px 36px 0;
        }
      }
    }
  }

  @media (max-width: 455px) {
    .content {
      .buttons {
        ${CookieBarButton} {
          font-size: 0.71rem;
          line-height: 1.4;
        }
      }
    }
  }

  @media (max-width: 323px) {
    .content {
      .buttons {
        flex-direction: column;

        ${CookieBarButton} {
          border-left: none;
          text-align: center;
          margin: 16px 36px 0;
        }
      }
    }
  }
`;

export default StyledCookieBar;
