import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@/constants';

interface NavigationBarButtonLayoutProps {
  active: boolean;
}

interface NavigationBarButtonProps {
  active: boolean;
  activeDark?: boolean;
  activeLight?: boolean;
  children: React.ReactNode;
  onClick: () => void;
  transparent?: boolean;
}

const Button = styled.div`
  width: 122px;
  height: 44px;
  place-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.14px;
  color: ${COLORS.NAVIGATION_BAR_BUTTON.GRAY};
  cursor: pointer;
  user-select: none;

  .active {
    color: ${COLORS.NAVIGATION_BAR_BUTTON.BLACK};
  }

  :hover {
    color: ${COLORS.NAVIGATION_BAR_BUTTON.BLACK};
  }

  :active {
    color: ${COLORS.NAVIGATION_BAR_BUTTON.BLACK};
    font-size: 0.8125rem;
  }
`;

const NavigationBarButtonLayout = styled(Button)<NavigationBarButtonLayoutProps>`
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0%;
    height: 2px;
    background-color: ${COLORS.COLOR_PRIMARY};
    display: block;
    content: '';
    width: ${(props): string => (props.active ? '100%' : '0')};
    transition: ${(props): string => (props.active ? '0.3s' : '0')};
  }
`;

const NavigationBarButton = ({
  active,
  children,
  onClick,
}: NavigationBarButtonProps): JSX.Element => (
  <NavigationBarButtonLayout
    active={active}
    className="nav-bar-button"
    onClick={onClick}
  >
    <Button className={classNames({
      'nav-bar-button--button': true,
      active,
    })}
    >
      { children }
    </Button>
  </NavigationBarButtonLayout>
);

export default NavigationBarButton;
