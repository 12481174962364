import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Chevron, Help, ErrorIcon, SuccessIcon } from '@/assets/svgs';
import { Modal } from '@/components';
import { COLORS } from '@/constants';
import { Validation } from '@/interfaces';

interface SelectProps {
  className?: string;
  placeholder: string;
  name: string;
  options: {
    value: string;
    label: string;
  }[];
  onChange: (value: string) => void;
  requiredMark?: boolean;
  tooltipMessage?: string;
  validation?: Validation;
  isMobileScreen: boolean;
}

const Select = ({
  className,
  name,
  onChange,
  options,
  placeholder,
  requiredMark = false,
  tooltipMessage,
  validation,
  isMobileScreen,
}: SelectProps): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const selectRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const shouldShowValidation = validation && validation.type !== 'NONE';
  const [portalExist, setPortalExist] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOnActivatorClick = (): void => {
    setIsActive((previousState) => !previousState);
  };

  const handleOnOptionClick = (option: {value: string, label: string}): void => {
    setSelectedValue(t(option.label));
    onChange(option.value);
    setIsActive(false);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    const menuRefNode = selectRef.current;
    const eventTarget = event.target;

    if (menuRefNode && !menuRefNode.contains(eventTarget as Node)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    if (document && document.getElementById('modal-portal')) {
      setPortalExist(true);
    }
    return (): void => {
      setPortalExist(false);
    };
  }, []);

  useEffect(() => {
    if (isModalVisible && !isMobileScreen) {
      setIsModalVisible(false);
    }
  }, [isModalVisible, isMobileScreen]);

  const handleOnTooltipClick = (): void => {
    if (!isMobileScreen) {
      return;
    }
    setIsModalVisible(true);
  };

  return (
    <div
      ref={selectRef}
      className={className}
    >
      <label
        className={`select-label ${isActive || selectedValue ? '--active' : ''}`}
        htmlFor={name}
      >
        { placeholder }
      </label>
      <div
        className={`select ${isActive ? '--active' : ''} ${selectedValue ? '--filled' : ''}`}
        onClick={(): void => setIsActive((previousState) => !previousState)}
      >
        { selectedValue }
      </div>
      <div
        className={`select-activator ${isActive ? '--active' : ''}`}
        onClick={handleOnActivatorClick}
      >
        <Chevron />
      </div>
      { isActive && (
        <div className="select-options">
          {
            options.map(({ value, label }) => (
              <div onClick={(): void => handleOnOptionClick({ value, label })}>{ t(label) }</div>
            ))
          }
        </div>
      ) }
      <div className="tips">
        { tooltipMessage && (
          <div
            className="tooltip"
            onClick={handleOnTooltipClick}
          >
            <Help />
            { !isMobileScreen && (
              <div className="tooltip-message">
                <div className="tooltip-message-text">
                  { tooltipMessage }
                  { requiredMark && '*' }
                </div>
              </div>
            ) }
          </div>
        ) }
        { shouldShowValidation && (
          <div className="validation">
            { validation && validation.type === 'ERROR'
              ? (
                <>
                  <ErrorIcon />
                  <div className="error-message">{ validation.message }</div>
                </>
              )
              : (<SuccessIcon />) }
          </div>
        ) }
      </div>
      { portalExist && (
        <Modal
          onClose={(): void => setIsModalVisible(false)}
          open={isModalVisible && isMobileScreen}
          text={tooltipMessage || ''}
        />
      ) }
    </div>
  );
};

const StyledSelect = styled(Select)`
  position: relative;

  .select-label {
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.FORM.PLACEHOLDER};
    position: absolute;
    transition: 0.15s;
    top: 0;
    pointer-events: none;

    @media (max-width: 575px) {
      font-size: 14px;
    }

    &.--active {
      font-size: 12px;
      line-height: 14px;
      top: -18px;
      transition: 0.15s;

      @media (max-width: 575px) {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .select {
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.FORM.TEXT};
    padding-bottom: 14px;
    border-bottom: 2px solid ${COLORS.FORM.BORDER};

    &.--active, &.--filled {
      border-color: ${COLORS.FORM.BORDER_FOCUSED};
    }
  }

  .select-activator {
    position: absolute;
    top: 0;
    right: 8px;
    user-select: none;
    width: 24px;
    height: 24px;
    margin-top: 2px;
    transform: rotate(-90deg);

    #chevron {
      stroke: ${COLORS.FORM.SELECT_CHEVRON};
    }

    &.--active {
      transform: rotate(90deg);
    }
  }

  .select-options {
    position: absolute;
    width: 100%;
    background-color: ${COLORS.FORM.SELECT_BACKGROUND};
    box-shadow: 0 0 12px 0 ${COLORS.FORM.SELECT_OPTIONS_SHADOW};
    padding: 6px;
    border-radius: 2px;
    margin-top: 6px;
    z-index: 5;
    color: ${COLORS.FORM.TEXT};
    font-size: 14px;
    line-height: 20px;
    max-height: 148px;
    overflow: scroll;
    overflow-x: hidden;

    div {
      height: 34px;
      display: flex;
      place-items: center;
      padding-left: 14px;
      list-style: none;

      :hover {
        background-color: ${COLORS.FORM.SELECT_HOVER};
      }
    }
  }

  .tips {
    display: flex;
    margin-top: 4px;
    height: 24px;

    .tooltip {
      position: relative;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;

      &:hover {
        #Help {
          fill: ${COLORS.COLOR_SECONDARY};
        }

        .tooltip-message {
          display: initial;
        }
      }
    }

    .tooltip-message {
      position: absolute;
      background-color: ${COLORS.TOOLTIP_BACKGROUND};
      padding: 12px 16px; 
      box-shadow: ${COLORS.SHADOWS.TOOLTIP_MESSAGE};
      border-radius: 4px;
      top: 0;
      left: 27px;
      z-index: 6;
      display: none;
      width: max-content;
      max-width: 340px;
    }

    .tooltip-message-text {
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: ${COLORS.TOOLTIP_TEXT};
    }

    .validation {
      display: flex;
      align-items: center;
    }

    #ErrorIcon {
      min-width: 24px;
    }

    .error-message {
      margin-left: 4px;
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
      color: ${COLORS.ERROR_MESSAGE};
      text-align: left;
    }

    @media (max-width: 900px) {
      .tooltip-message {
        max-width: 300px;
      }
    }
  }

`;

export default StyledSelect;
