import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@/constants';

const ProgressBarContainer = styled.div`
  height: 16px;
  max-width: 812px;
  width: 100%;
  background-color: ${COLORS.PROGRESS_BAR_BACKGROUND};
  margin: 0 auto 34px auto;

  @media (max-width: 1399px) {
    max-width: 710px;
    margin-bottom: 30px;
  }

  @media (max-width: 991px) {
    max-width: 84%;
  }

  @media (max-width: 767px) {
    max-width: 90%;
    height: 12px;
    margin-bottom: 20px;
  }

  @media (max-width: 575px) {
    max-width: unset;
  }
`;

interface ProgressBarFillerProps {
  completed: number;
}

const ProgressBarFiller = styled.div<ProgressBarFillerProps>`
  height: 100%;
  width: ${({ completed }): string => `${completed}%`};
  background-image: ${COLORS.PROGRESS_BAR_FILLER};
  text-align: right;
`;

interface ProgressBarProps {
  completed: number;
}

const ProgressBar = ({ completed }: ProgressBarProps): JSX.Element => (
  <ProgressBarContainer>
    <ProgressBarFiller completed={completed} />
  </ProgressBarContainer>
);

export default ProgressBar;
