import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Detail } from '@/components';
import { COLORS, UNITS } from '@/constants';
import { Response } from '@/interfaces';
import { convertFloatToCurrency } from '@/utils/formatter';

const FinancialDetailsLayout = styled.div`  
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  
  > .section-container {
    background-color: ${COLORS.INVESTMENT_FORM_BACKGROUND};
    padding: 120px 138px 140px 138px;

    @media (max-width: 1399px) {
      padding: 100px 138px 115px 138px;
    }

    @media (max-width: 991px) {
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 80px;
    }
  }

  .container {
    @media (max-width: 991px) {
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
    }
  }

  .financial-detail-header {
    @media (max-width: 991px) {
      font-weight: bold;
    }

    @media (max-width: 767px) {
      margin-bottom: 34px;
    }
  }

  .paragraph {
    width: 536px;
    max-width: 100%;
  }

  .separator {
    border: none;
    height: 1px;
    margin: 64px 0 44px 0;
    background-color: ${COLORS.SUMMARY_SEPARATOR};
    color: ${COLORS.SUMMARY_SEPARATOR};

    @media (max-width: 1399px) {
      margin: 54px 0 34px 0;
    }

    @media (max-width: 767px) {
      margin-top: 40px;
      margin-bottom: 34px;
    }
  }

  .financial-details-stats {
    display: grid;
    grid-template-columns: repeat(5, minmax(100px, 260px));
    grid-row-gap: 44px;
    grid-column-gap: 16px;
    
    @media (max-width: 1399px) {
      grid-template-columns: repeat(4, minmax(204px, 260px));
    }

    @media (max-width: 1199px) {
      grid-template-columns: repeat(2, 50%);
    }

    @media (max-width: 767px) {
      grid-column-gap: 10px;
      grid-row-gap: 24px;
    }

    @media (max-width: 374px) {
      grid-template-columns: 100%;
    }
  }
`;

interface FinancialDetailsProps {
  currency: string;
  response: Response;
}

const FinancialDetails = ({ currency, response }: FinancialDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FinancialDetailsLayout>
      <div className="section-container">
        <div className="container">
          <h2 className="financial-detail-header">{ t('summary:financial-details.title') }</h2>
          <p className="paragraph">{ t('summary:financial-details.sub-title') }</p>
          <hr className="separator" />
          <div className="financial-details-stats">
            <Detail
              contentText={convertFloatToCurrency(String(response.possibleFunding), false)}
              title="investmentForm:financial-details.possible-funding"
              unit={currency}
            />
            <Detail
              contentText={convertFloatToCurrency(String(response.rent), false)}
              title="investmentForm:financial-details.required-finance"
              unit={currency}
            />
            <Detail
              contentText={convertFloatToCurrency(String(Number(response.roeDebt) * 100), true)}
              title="investmentForm:financial-details.return-loan"
              unit={UNITS.PERCENTAGE}
            />
            <Detail
              contentText={convertFloatToCurrency(String(Number(response.roeNoDebt) * 100), true)}
              title="investmentForm:financial-details.return-cash"
              unit={UNITS.PERCENTAGE}
            />
          </div>
        </div>
      </div>
    </FinancialDetailsLayout>
  );
};

export default FinancialDetails;
