export default {
  CONTACT_FORM: {
    QUESTION: {
      MIN_LENGTH: 8,
      MAX_LENGTH: 256,
    },
    EMAIL: {
      // eslint-disable-next-line no-useless-escape
      PATTERN: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i,
    },
    PHONE_NUMBER: {
      MIN_LENGTH: 9,
      MAX_LENGTH: 17,
      PATTERN: /^\+?[0-9]{9,17}$/,
    },
  },
};
