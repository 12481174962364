import { MenuImage } from '@/assets/images';
import { IconClose, Logo } from '@/assets/svgs';
import React from 'react';
import styled from 'styled-components';
import StyledButtonsSection, { NAVIGATIONBAR_BUTTON_TYPE } from '../ButtonsSection';

interface MobileMenuProps {
  className?: string;
  initialValue: NAVIGATIONBAR_BUTTON_TYPE;
  onClose: () => void;
  onButtonClick: (type: NAVIGATIONBAR_BUTTON_TYPE) => void;
}

const MobileMenu = ({ className, initialValue, onClose, onButtonClick }: MobileMenuProps): JSX.Element => (
  <div className={className}>
    <div className="upper-bar">
      <Logo className="test" />
      <IconClose onClick={onClose} />
    </div>
    <StyledButtonsSection
      className="mobile-menu-buttons"
      initialValue={initialValue}
      isMobile
      isSubmitVisible
      onClick={onButtonClick}
    />
  </div>
);

const StyledMobileMenu = styled(MobileMenu)`
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;

  .upper-bar {
    padding: 0 36px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    place-items: center;
    border-bottom: 1px solid #e6e6e6;

    > * {
      cursor: pointer;
    }
  }

  ${StyledButtonsSection} {
    display: flex;
    height: calc(100% - 100px);
    place-items: center;
    justify-content: center;

    background-image: url('${MenuImage}');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: -75px;

    .buttons-section {
      > * {
        margin-bottom: 40px;
        margin-left: 0;
        font-size: 14px;
      }

      .nav-bar-button--button,
      .button {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.navbar-fixed {
    .upper-bar {
      height: 64px;
    }

    ${StyledButtonsSection} {
      height: calc(100% - 64px);
    }
  }
`;

export default StyledMobileMenu;
