import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

import './fonts.css';
import { Favicon } from '@/assets/images';
import StyledCookieBar from '@/components/CookieBar';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    height: 100%;

    @media (max-width: 1399px) {
      font-size: 14px;
    }
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Open Sans";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #___gatsby {
      height: 100%;
    }
    
    #gatsby-focus-wrapper {
      height: 100%;
    }
        
    *, *::before, *::after {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 2.125rem;
      font-family: "Roboto Slab";
    }

    h2 {
      font-size: 2.125rem;
      margin-bottom: 2.125rem;
      font-family: "Roboto Slab";
      font-weight: normal;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      font-family: "Roboto Slab";
      font-weight: normal;
    }

    @media (max-width: 575px) {
      h2 {
        font-size: 24px;
        margin-bottom: 34px;
      }
    }
  }

  .section-container {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 991px) {
      width: 100%;
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (min-width: 992px) {
      width: 960px;
    }

    @media (min-width: 1200px) {
      width: 1140px;
    }

    @media (min-width: 1400px) {
      width: 1320px;
    }

    @media (min-width: 1680px) {
      width: 1632px;
    }
  }
`;

const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <React.Fragment>
    <Helmet>
      <link
        href={Favicon}
        rel="icon"
      />
    </Helmet>
    <GlobalStyle />
    {children}
    <StyledCookieBar
      marketingCookies={[
        {
          name: 'gdpr-analytics-enabled',
          cookieFunction: (): void => { window.trackHotjar(); },
        },
      ]}
    />
  </React.Fragment>
);

export default Layout;
