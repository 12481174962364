/* eslint-disable indent */
import axios from 'axios';
import { navigate } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Input,
  FormButton,
  Select,
} from '@/components';
import { COLORS, UNITS, currencies, statuses } from '@/constants';
import { FormData, ProductKind } from '@/interfaces';
import { ValidationTypes, FormButtonStatuses } from '@/types';
import { formatAsNumeric } from '@/utils/formatter';
import isMobile from '@/utils/isMobile';
import setTimeoutPromisified from '@/utils/setTimeoutPromisified';

interface InvestmentFormContentProps {
  formButtonStatus: string;
}

const InvestmentFormContent = styled.div<InvestmentFormContentProps>`
  margin-bottom: 40px;
  position: relative;

  > .section-container {
    padding: 120px 138px 118px 138px;
    background-color: ${COLORS.INVESTMENT_FORM_BACKGROUND};

    @media (max-width: 1399px) {
      padding: 100px 138px 115px 138px;
    }

    @media (max-width: 991px) {
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (max-width: 767px) {
      padding: 40px 36px;
    }
  }

  .scroll-ref {
    position: absolute;
    top: -100px;

    @media (max-width: 991px) {
      top: -64px;
    }
  }

  .container {
    @media (max-width: 991px) {
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
    }
  }

  .product-title {
    margin-bottom: 0;
  }

  .product-changer {
    font-size: 12px;
    line-height: 1.33;
    text-decoration: underline;
    margin-bottom: 14px;
    cursor: pointer;

    @media (max-width: 575px) {
      margin-bottom: 10px;
    }
  }

  .paragraph {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 536px;
    max-width: 100%;

    @media (max-width: 1399px) {
      width: 475px;
    }
  }

  .send-button {
    margin-top: 100px;
    width: 218px;

    @media (max-width: 1399px) {
      margin-top: 80px;
    }

    @media (max-width: 767px) {
      margin-top: 60px;
    }
    
    .form-button {
      &:hover {
        .button, .arrow-box {
        background-color: ${({ formButtonStatus }): string => (
          formButtonStatus === 'ERROR' ? COLORS.FORM.BUTTON_ERROR : COLORS.BUTTON_SECONDARY_HOVER)
        };
        color: ${COLORS.TEXT_PRIMARY};
        }
      }

      .button, .arrow-box {
        background-color: ${({ formButtonStatus }): string => (
          formButtonStatus === 'ERROR' ? COLORS.FORM.BUTTON_ERROR : COLORS.COLOR_SECONDARY)
        };
        color: ${COLORS.TEXT_PRIMARY};
      }

      .button {
        @media (max-width: 1399px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .arrow-box {
        .arrow-icon {
          #arrow {
            stroke: white;
          }
        }
      }

      .rcs-roller {
        --rcs-roller-color: white !important;
      }
    }

    .error-label {
      .message {
        color: ${COLORS.TEXT_BUTTON_SECONDARY};
      }
    }
  }

  .offer-searching {
    font-size: 0.75rem;
    line-height: 1.33;
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const Form = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 400px));
  grid-row-gap: 60px;
  grid-column-gap: 85px;

  @media (max-width: 1399px) {
    margin-top: 65px;
  }

  @media (max-width: 991px) {
    max-width: 696px;
    width: 100%;
  }

  @media (max-width: 767px) {
    margin-top: 53px;
  }

  @media (max-width: 575px) {
    grid-row-gap: 34px;
  }

  .form-input {
    .input-label {
      @media (max-width: 359px) {
        font-size: 12px;
        line-height: 24px;
      }

      &.--active {
        @media (max-width: 575px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    .input {
      padding-bottom: 8px;

      @media (max-width: 575px) {
        line-height: 24px;
      }
    }

    .unit {
      @media (max-width: 359px) {
        font-size: 12px;
        line-height: 24px;
      }
    }
  }

  .form-select {
    .select-label {
      @media (max-width: 359px) {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .select {
      height: 34px;
      padding-bottom: 8px;
    }

    .select-activator {
      @media (max-width: 359px) {
        width: 20px;
        height: 20px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    grid-column-gap: 90px;
    grid-template-columns: repeat(2, minmax(300px, 477px));
  }

  @media (max-width: 1399px) {
    grid-column-gap: 85px;
    grid-template-columns: repeat(2, minmax(300px, 400px));
  }

  @media (max-width: 1199px) {
    grid-template-columns: 100%;
  }
`;

interface InvestmentFormProps {
  productKind: ProductKind;
}

const InvestmentForm = ({ productKind }: InvestmentFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, errors, formState, trigger } = useForm({
    mode: 'onChange',
  });
  const [availableLocations, setAvailableLocations] = useState([]);
  const { dirtyFields } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [buttonStatus, setButtonStatus] = useState<FormButtonStatuses>('SUBMIT');
  const [leaseRateUnit, setLeaseRateUnit] = useState(UNITS.PLN_PER_QUADRAMETER);
  const [yearPlaceholder, setYearPlaceholder] = useState('investmentForm:year-of-construction');

  const getLocalizations = async (): Promise<void> => {
    try {
      const response = await axios.get(`${process.env.GATSBY_REST_API_ENDPOINT}/yields`);

      const locations = response.data.map((location: string) => {
        if (location === 'OTHER') {
          return {
            label: 'investmentForm:other',
            value: 'OTHER',
          };
        }

        return {
          label: location,
          value: location,
        };
      });
      setAvailableLocations(locations);
    } catch {
      navigate('/error/');
    }
  };

  const navigateToProceed = (data: FormData): void => {
    setIsLoading(true);
    trigger();
    setTimeoutPromisified(500);
    navigate('/processing/', {
      state: {
        payload: {
          data: {
            productKind: productKind.value,
            ...data,
          },
        },
      },
    });
  };

  useEffect(() => {
    getLocalizations();
    register({ name: 'status' }, { required: t('investmentForm:errors.empty-check').toString() });
    register({ name: 'estatePrice' }, { required: t('investmentForm:errors.empty-check').toString() });
    register({ name: 'area' }, { required: t('investmentForm:errors.empty-check').toString() });
    register({ name: 'currency' }, { required: t('investmentForm:errors.empty-check').toString() });
    register({ name: 'leaseRate' }, { required: t('investmentForm:errors.empty-check').toString() });
    register({ name: 'serviceFee' }, { required: t('investmentForm:errors.empty-check').toString() });
    register(
      { name: 'yearOfConstruction' },
      {
        required: t('investmentForm:errors.empty-check').toString(),
        minLength: { value: 4, message: t('investmentForm:errors.invalid-year') },
        maxLength: { value: 4, message: t('investmentForm:errors.invalid-year') },
      },
    );
    register({ name: 'localization' }, { required: t('investmentForm:errors.empty-check').toString() });
    register({ name: 'link' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFieldValue = (name: string, value: string, validate: boolean): void => {
    setValue(name, value, { shouldValidate: validate, shouldDirty: true });
    if (name === 'currency') {
      if (value === 'EUR') {
        setLeaseRateUnit(UNITS.EUR_PER_QUADRAMETER);
      } else {
        setLeaseRateUnit(UNITS.PLN_PER_QUADRAMETER);
      }
    }
    if (name === 'status') {
      if (value === 'UNDER_CONSTRUCTION') {
        setYearPlaceholder('investmentForm:year-of-construction');
      } else {
        setYearPlaceholder('investmentForm:year-of-permission');
      }
    }
  };

  const getValidationStatus = (name: string): ValidationTypes => {
    if (name in errors) {
      return 'ERROR';
    }
    if (name in errors && name in dirtyFields) {
      return 'ERROR';
    }
    if (name in dirtyFields && name in errors === false) {
      return 'SUCCESS';
    }

    return 'NONE';
  };

  const changeProduct = (): void => {
    scrollTo('#productSelector-scroll-ref', 'start');
  };

  const handleResize = (): void => {
    setIsMobileScreen(isMobile());
  };

  useEffect(() => {
    handleResize();

    if (window) {
      window.addEventListener('resize', handleResize);
    }
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <InvestmentFormContent formButtonStatus={buttonStatus}>
      <div
        className="scroll-ref"
        id="investmentForm-scroll-ref"
      />
      <div className="section-container">
        <div className="container">
          <h2 className="product-title">{ t(productKind.label) }</h2>
          <div onClick={changeProduct}>
            <p className="product-changer">{ t('common:change') }</p>
          </div>
          <p className="paragraph">{ t('investmentForm:fill-form') }</p>
          <Form>
            <Select
              className="form-select"
              isMobileScreen={isMobileScreen}
              name="status"
              onChange={(value: string): void => setFieldValue('status', value, true)}
              options={statuses}
              placeholder={t('investmentForm:status')}
              tooltipMessage={t('formTooltips:status')}
              validation={{
                type: getValidationStatus('status'),
                message: t(errors?.status?.message),
              }}
            />
            <Input
              formatter={formatAsNumeric}
              isMobileScreen={isMobileScreen}
              name="estatePrice"
              onChange={(value: string): void => setFieldValue('estatePrice', value, true)}
              placeholder={t('investmentForm:estate-price')}
              tooltipMessage={t('formTooltips:estate-price')}
              unit={UNITS.PLN}
              validation={{
                type: getValidationStatus('estatePrice'),
                message: t(errors?.estatePrice?.message),
              }}
            />
            <Input
              formatter={formatAsNumeric}
              isMobileScreen={isMobileScreen}
              name="area"
              onChange={(value: string): void => setFieldValue('area', value, true)}
              placeholder={t('investmentForm:area')}
              tooltipMessage={t('formTooltips:area')}
              unit={UNITS.QUADRAMETER}
              validation={{
                type: getValidationStatus('area'),
                message: t(errors?.area?.message),
              }}
            />
            <Select
              className="form-select"
              isMobileScreen={isMobileScreen}
              name="currency"
              onChange={(value: string): void => setFieldValue('currency', value, true)}
              options={currencies}
              placeholder={t('investmentForm:currency')}
              tooltipMessage={t('formTooltips:currency')}
              validation={{
                type: getValidationStatus('currency'),
                message: t(errors?.currency?.message),
              }}
            />
            <Input
              formatter={formatAsNumeric}
              isMobileScreen={isMobileScreen}
              name="leaseRate"
              onChange={(value: string): void => setFieldValue('leaseRate', value, true)}
              placeholder={t('investmentForm:lease-rate')}
              tooltipMessage={t('formTooltips:lease-rate')}
              unit={leaseRateUnit}
              validation={{
                type: getValidationStatus('leaseRate'),
                message: t(errors?.leaseRate?.message),
              }}
            />
            <Input
              formatter={formatAsNumeric}
              isMobileScreen={isMobileScreen}
              name="serviceFee"
              onChange={(value: string): void => setFieldValue('serviceFee', value, true)}
              placeholder={t('investmentForm:service-fee')}
              tooltipMessage={t('formTooltips:service-fee')}
              unit={UNITS.PLN_PER_QUADRAMETER}
              validation={{
                type: getValidationStatus('serviceFee'),
                message: t(errors?.serviceFee?.message),
              }}
            />
            <Input
              isMobileScreen={isMobileScreen}
              isNumeric
              name="yearOfConstruction"
              onChange={(value: string): void => setFieldValue('yearOfConstruction', value, true)}
              placeholder={t(yearPlaceholder)}
              tooltipMessage={t('formTooltips:year-of-construction')}
              validation={{
                type: getValidationStatus('yearOfConstruction'),
                message: t(errors?.yearOfConstruction?.message),
              }}
            />
            <Select
              className="form-select"
              isMobileScreen={isMobileScreen}
              name="localization"
              onChange={(value: string): void => setFieldValue('localization', value, true)}
              options={availableLocations}
              placeholder={t('investmentForm:localization')}
              tooltipMessage={t('formTooltips:localization')}
              validation={{
                type: getValidationStatus('localization'),
                message: t(errors?.localization?.message),
              }}
            />
            <Input
              isMobileScreen={isMobileScreen}
              name="link"
              onChange={(value: string): void => setFieldValue('link', value, false)}
              placeholder={t('investmentForm:link')}
              tooltipMessage={t('formTooltips:link')}
            />
          </Form>
          <FormButton
            className="send-button"
            loading={isLoading}
            onClick={handleSubmit(navigateToProceed)}
            onReset={(): void => setButtonStatus('SUBMIT')}
            status={buttonStatus}
            title={t('investmentForm:button')}
          />
        </div>
      </div>
    </InvestmentFormContent>
  );
};

export default InvestmentForm;
