import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { IconClose } from '@/assets/svgs';
import { COLORS } from '@/constants';

interface ModalProps {
  buttonText?: string;
  open: boolean;
  onClose: () => void;
  onModalButtonClick?: () => void;
  text: string;
  title?: string;
  withButton?: boolean;
}

const ModalScrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000000000;
`;

const ModalLayout = styled.div`
  position: fixed;
  height: auto;
  background-color: white;
  right: 36px;
  bottom: 40px;
  left: 36px;

  .modal-close-icon {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    padding-right: 12px;
    cursor: pointer;
  }

  .modal-text {
    padding: 12px 24px 24px 24px;
    font-size: 0.85rem;
    line-height: 1.33;
    color: ${COLORS.TOOLTIP_TEXT};

    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .modal-title {
    font-size: 1rem;
    color: ${COLORS.MODAL_TITLE};
    padding: 0 24px;
  }

  .modal-button-section {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  .modal-button {
    background: none;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    color: ${COLORS.COLOR_SECONDARY};
    height: 32px;
  }
`;

const Modal = ({
  buttonText,
  onClose,
  onModalButtonClick,
  open,
  text,
  title,
  withButton,
}: ModalProps): JSX.Element | null => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      disableBodyScroll(modalRef.current as HTMLElement);
    }
    return (): void => {
      clearAllBodyScrollLocks();
    };
  }, [open]);

  const handleOnModalClose = (): void => {
    enableBodyScroll(modalRef.current as HTMLElement);
    onClose();
  };

  const handleOnScrimClick = (event: React.MouseEvent): void => {
    if (modalRef && !modalRef.current?.contains(event.target as Node)) {
      enableBodyScroll(modalRef.current as HTMLElement);
      onClose();
    }
  };

  return (open
    ? createPortal(
      <ModalScrim onClick={(event: React.MouseEvent): void => handleOnScrimClick(event)}>
        <ModalLayout ref={modalRef}>
          <div className="modal-close-icon">
            <IconClose onClick={handleOnModalClose} />
          </div>
          {
            title && (
              <div className="modal-title">{ title }</div>
            )
          }
          <div className="modal-text">{ text }</div>
          {
            withButton && (
              <div className="modal-button-section">
                <button
                  className="modal-button"
                  onClick={onModalButtonClick}
                  type="button"
                >
                  { buttonText }
                </button>
              </div>
            )
          }
        </ModalLayout>
      </ModalScrim>,
        document.getElementById('modal-portal') as HTMLElement,
    )
    : null);
};

export default Modal;
