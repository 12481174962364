import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LogoFooter, SygnetFooter } from '@/assets/svgs';
import FormButton from '@/components/Button/FormButton';
import StyledCheckboxWithDescription from '@/components/CheckboxWithDescription';
import StyledInput from '@/components/Input';
import { COLORS, FORM_RULES, ROUTES } from '@/constants';
import { Validation } from '@/interfaces';
import isMobile from '@/utils/isMobile';

type FormValues = {
  email: string;
  phoneNumber: string;
  emailContactAgreement: boolean;
  phoneContactAgreement: boolean;
  question: string;
};

interface ContactFormProps {
  className?: string;
  id?: string;
  location?: Record<string, unknown>;
  showContactDetails?: boolean;
}

const defaultValues = {
  email: '',
  emailContactAgreement: false,
  phoneNumber: '',
  phoneContactAgreement: false,
  question: '',
};

const ContactForm = ({ className, id, location, showContactDetails = false }: ContactFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { control, reset, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: { ...defaultValues },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [afterFirstValidation, setAfterFirstValidation] = useState<boolean>();
  const [buttonStatus, setButtonStatus] = useState<'SUBMIT' | 'SUCCESS' | 'ERROR'>('SUBMIT');
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const url = process.env.GATSBY_REST_API_ENDPOINT?.concat(ROUTES.CONTACT_FORM);
  const requiredErrorMessage = t('contactForm:errors.required');

  const getLeadType = (): 'CLIENT' | 'PARTNER' | 'UNKNOWN' => {
    switch (location?.pathname) {
      case '/':
        return 'CLIENT';
      case '/banks':
        return 'PARTNER';
      default:
        return 'UNKNOWN';
    }
  };

  const sendContactFormData = async (data: FormValues): Promise<void> => {
    const {
      email,
      emailContactAgreement,
      phoneNumber,
      phoneContactAgreement,
      question,
    } = data;

    try {
      setIsLoading(true);
      await axios.post(`${url}`, {
        email,
        emailContactAgreement,
        phoneNumber,
        phoneContactAgreement,
        leadType: getLeadType(),
        question,
      });
      reset({ ...defaultValues });
      setButtonStatus('SUCCESS');
    } catch {
      setButtonStatus('ERROR');
    } finally {
      setIsLoading(false);
    }
  };

  const validate = (name: string): Validation => {
    if (afterFirstValidation && Object.keys(errors).includes(name)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { type: 'ERROR', message: (errors as any)[name].message };
    }

    return { type: 'NONE', message: '' };
  };

  const handleResize = (): void => {
    setIsMobileScreen(isMobile());
  };

  useEffect(() => {
    handleResize();

    if (window) {
      window.addEventListener('resize', handleResize);
    }
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={className}>
      <div
        className="scroll-ref"
        id={id}
      />
      <div className="section-container">
        <div className="half-container">
          <div className="heading">
            <h2>{ t('contactForm:title') }</h2>
            <p>{ t('contactForm:description') }</p>
          </div>
          <SygnetFooter className="sygnet-footer" />
          {
            showContactDetails && (
              <div className="contact-details">
                <LogoFooter />
                <div className="details">
                  <p>{ t('contactForm:contact-details.name') }</p>
                  <p>{ t('contactForm:contact-details.street') }</p>
                  <p>{ t('contactForm:contact-details.zip-code') }</p>
                  <p>{ t('contactForm:contact-details.phone-first') }</p>
                  <p>{ t('contactForm:contact-details.phone-second') }</p>
                  <p>{ t('contactForm:contact-details.email') }</p>
                </div>
              </div>
            )
          }
        </div>
        <div className="half-container">
          <div className="form-container">
            <div className="input-section">
              <Controller
                control={control}
                name="question"
                render={({ onChange, value, name }): JSX.Element => (
                  <StyledInput
                    className="input-question"
                    isMobileScreen={isMobileScreen}
                    name={name}
                    onChange={onChange}
                    placeholder={t('contactForm:field.question.title')}
                    requiredMark
                    validation={validate('question')}
                    value={value}
                  />
                )}
                rules={
                  {
                    required: requiredErrorMessage,
                    minLength: {
                      value: FORM_RULES.CONTACT_FORM.QUESTION.MIN_LENGTH,
                      message: t('contactForm:errors.min-required-characters'),
                    },
                    maxLength: {
                      value: FORM_RULES.CONTACT_FORM.QUESTION.MAX_LENGTH,
                      message: t('contactForm:errors.max-allowed-characters'),
                    },
                  }
                }
              />
              <div className="flex-box">
                <Controller
                  control={control}
                  name="email"
                  render={({ onChange, value, name }): JSX.Element => (
                    <StyledInput
                      className="input input-email"
                      isMobileScreen={isMobileScreen}
                      name={name}
                      onChange={onChange}
                      placeholder={t('contactForm:field.email.title')}
                      requiredMark
                      validation={validate('email')}
                      value={value}
                    />
                  )}
                  rules={{
                    required: requiredErrorMessage,
                    pattern: {
                      value: FORM_RULES.CONTACT_FORM.EMAIL.PATTERN,
                      message: t('contactForm:errors.invalid-email'),
                    },
                  }}
                />
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ onChange, value, name }): JSX.Element => (
                    <StyledInput
                      className="input input-phone-number"
                      // eslint-disable-next-line no-useless-escape
                      formatter={(phoneInput): string => phoneInput.replace(/[^\+0-9]/g, '')}
                      isMobileScreen={isMobileScreen}
                      name={name}
                      onChange={onChange}
                      placeholder={t('contactForm:field.phone.title')}
                      requiredMark
                      validation={validate('phoneNumber')}
                      value={value}
                    />
                  )}
                  rules={{
                    required: requiredErrorMessage,
                    pattern: {
                      value: FORM_RULES.CONTACT_FORM.PHONE_NUMBER.PATTERN,
                      message: t('contactForm:errors.invalid-phone'),
                    },
                    minLength: {
                      value: FORM_RULES.CONTACT_FORM.PHONE_NUMBER.MIN_LENGTH,
                      message: t('contactForm:errors.invalid-phone'),
                    },
                    maxLength: {
                      value: FORM_RULES.CONTACT_FORM.PHONE_NUMBER.MAX_LENGTH,
                      message: t('contactForm:errors.invalid-phone'),
                    },
                  }}
                />
              </div>
              <div className="flex-box">
                <Controller
                  control={control}
                  defaultValue={false}
                  name="emailContactAgreement"
                  render={({ onChange, value }): JSX.Element => (
                    <StyledCheckboxWithDescription
                      agreement={{
                        label: t('contactForm:show-whole'),
                        content: t('contactForm:email-agreement'),
                      }}
                      className="form-agreement"
                      description={t('contactForm:field.email.confirmation')}
                      isMobileScreen={isMobileScreen}
                      onChange={onChange}
                      requiredMark
                      validation={validate('emailContactAgreement')}
                      value={value}
                    />
                  )}
                  rules={{
                    required: requiredErrorMessage,
                  }}
                />
                <Controller
                  control={control}
                  defaultValue={false}
                  name="phoneContactAgreement"
                  render={({ onChange, value }): JSX.Element => (
                    <StyledCheckboxWithDescription
                      agreement={{
                        label: t('contactForm:show-whole'),
                        content: t('contactForm:phone-agreement'),
                      }}
                      className="form-agreement"
                      description={t('contactForm:field.phone.confirmation')}
                      isMobileScreen={isMobileScreen}
                      onChange={onChange}
                      requiredMark
                      validation={validate('phoneContactAgreement')}
                      value={value}
                    />
                  )}
                  rules={{
                    required: requiredErrorMessage,
                  }}
                />
              </div>
            </div>
            <div className="button-section">
              <FormButton
                loading={isLoading}
                onClick={(): void => {
                  setAfterFirstValidation(true);
                  handleSubmit(sendContactFormData)();
                }}
                onReset={(): void => setButtonStatus('SUBMIT')}
                status={buttonStatus}
              />
              <span>{ t('contactForm:required') }</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledContactForm = styled(ContactForm)`
  background-color: ${COLORS.COLOR_SECONDARY};
  color: ${COLORS.TEXT_PRIMARY};
  position: relative;

  > .section-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;

    @media (max-width: 1399px) {
      padding-top: 65px;
      padding-bottom: 65px;
    }

    @media (max-width: 991px) {
      max-width: 768px;
    }

    @media (max-width: 767px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .heading {
    max-width: 536px;
    position: relative;
    z-index: 10;

    @media (max-width: 1399px) {
      max-width: 485px;
    }

    h2 {
      @media (max-width: 575px) {
        margin-bottom: 24px;
      }
    }
  }

  .scroll-ref {
    position: absolute;
    top: -100px;

    @media (max-width: 991px) {
      top: -64px;
    }
  }

  .flex-box {
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    &:last-child {
      @media (max-width: 991px) {
        padding-left: 40px;
      }

      @media (max-width: 767px) {
        padding-left: 0;
      }
    }
    
    > :first-of-type {
      margin-right: 50px;
    }

    @media (max-width: 1199px) {
      > :first-of-type {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    @media (max-width: 991px) {
      > :first-of-type {
        margin-bottom: 0;
      }
    }

    @media (max-width: 767px) {
      > :first-of-type {
        margin-bottom: 24px;
      }
    }

    .form-agreement {
      &:nth-child(2) {
        .description {
          max-width: 236px;

          @media (max-width: 767px) {
            max-width: unset;
          }
        }
      }
    }
  }

  .form-button {
    width: min-content;

    .button {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .button-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    max-width: 674px;

    @media (min-width: 1200px) {
      flex-direction: row;
    }

    > span {
      width: 264px;
      max-width: 100%;
      margin-top: 44px;
      padding-left: 0;
      line-height: 16px;
      font-size: 12px;
      color: ${COLORS.FOOTER_MAIN_COLOR};

      @media (max-width: 1399px) {
        margin-top: 48px;
      }

      @media (max-width: 1199px) {
        margin-top: 0;
        margin-bottom: 34px;
      }

      @media (max-width: 991px) {
        padding-left: 39px;
      }
    }
  }

  .input-section {
    display: flex;
    flex-wrap: wrap;
    max-width: 674px;
    justify-content: space-between;

    @media (max-width: 991px) {
      max-width: unset;
    }

    .form-input.input-question {
      width: 100%;
      min-width: 260px;
      max-width: inherit;

      @media (max-width: 575px) {
        width: 100%;
        min-width: unset;
        max-width: unset;
      }
    }

    .form-input {
      min-width: 260px;
      margin-bottom: 6px;
      margin-top: 20px;

      @media (max-width: 1199px) {
        min-width: 220px;
        max-width: 50%;
      }

      @media (max-width: 991px) {
        width: 50%;
      }

      @media (max-width: 575px) {
        width: 100%;
        min-width: unset;
        max-width: unset;
      }

      &.input-email {
        @media (max-width: 991px) {
          padding-right: 19px;
        }

        @media (max-width: 575px) {
          padding-right: 0;
        }
      }

      &.input-phone-number {
        @media (max-width: 1199px) {
          padding-left: 37px;
        }

        @media (max-width: 991px) {
          padding-left: 18px;
        }

        @media (max-width: 575px) {
          padding-left: 0;
        }
      }
    }

    .error-message {
      color: ${COLORS.FORM.ERROR_MESSAGE};
    }
  }

  .input-label {
    color: ${COLORS.FORM.INPUT_LABEL};

    &.--error {
      color: ${COLORS.ERROR_MESSAGE};
    }
  }

  .input {
    border-color: ${COLORS.FORM.BUTTON};
    caret-color: ${COLORS.FORM.INPUT_CARET};
    color: ${COLORS.TEXT_PRIMARY};
    padding-bottom: 8px;

    &.--filled,
    :focus {
      border-color: ${COLORS.FORM.INPUT_FOCUS};
    }
  }

  ${StyledCheckboxWithDescription} {
    width: 300px;
    margin-left: -40px;

    @media (max-width: 1199px) {
      width: 100%;
    }

    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }

    .checkbox {
      margin-right: 19px;
    }

    .description {
      @media (max-width: 767px) {
        max-width: unset;
        white-space: unset;
      }
    }

    .tooltip {
      margin-left: 80px !important;
    }
  }

  .half-container {
    width: 50%;

    @media (max-width: 991px) {
      width: 100%;
    }

    .sygnet-footer {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;

      @media (max-width: 1679px) {
        width: 480px;
        height: auto;
      }

      @media (max-width: 1399px) {
        width: 440px;
      }

      @media (max-width: 1199px) {
        width: 350px;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    .contact-details {
      max-width: 300px;
      margin-left: 138px;

      .details {
        margin-top: 20px;

        p {
          font-size: 1rem;
          line-height: 1.5;

          :nth-of-type(3) {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .form-container {
      @media (max-width: 991px) {
        margin-top: 40px;
      }

      h2 {
        margin-bottom: 2.125rem;
      }

      p {
        max-width: 400px;
        margin-right: 36px;
        margin-bottom: 20px;
      }
    }
  }
`;

export default StyledContactForm;
