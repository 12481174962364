export const formatAsNumeric = (value: string): string => {
  const parsedValue = value
    .replace(/(^[,0]+)/g, '')
    .replace(/\s/g, '')
    .replace(/[^\d,]/g, '');

  return parsedValue
    .replace(/(\d+,\d{2})(.+)/g, '$1')
    // eslint-disable-next-line no-useless-escape
    .replace(/(?!^)(?=(\d{3})+(?=\,|$))/g, ' ');
};

export const roundToDecimal = (value: string): string => (
  value.replace(/,(\d{1})$/g, ',$10')
);

export const replaceCommaWithDot = (value: string): string => value.replace(/,/g, '.');

export const convertFloatToCurrency = (value: string, withFloats: boolean): string => {
  const integerValue = formatAsNumeric(value.replace(/\.[\d]*/g, ''));
  if (withFloats) {
    return `${integerValue || '0'},${value.replace(/[\d]*\./g, '').substring(0, 2)}`;
  }
  return integerValue;
};
