import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import StyledCheckbox from './Checkbox';
import { Modal } from '@/components';
import { COLORS } from '@/constants';
import { Validation } from '@/interfaces';

interface CheckboxWithDescriptionProps {
  agreement?: { label: string, content: string };
  className?: string;
  description: string;
  isMobileScreen: boolean;
  name?: string;
  onChange?: (value: boolean) => void;
  requiredMark?: boolean;
  validation?: Validation;
  value?: boolean;
}

const CheckboxWithDescription = ({
  agreement,
  className,
  description,
  isMobileScreen,
  onChange,
  requiredMark = false,
  validation,
  value = false,
}: CheckboxWithDescriptionProps): JSX.Element => {
  const [portalExist, setPortalExist] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkboxState, setCheckboxState] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (document && document.getElementById('modal-portal')) {
      setPortalExist(true);
    }
    return (): void => {
      setPortalExist(false);
    };
  }, []);

  useEffect(() => {
    if (isModalVisible && !isMobileScreen) {
      setIsModalVisible(false);
    }
  }, [isModalVisible, isMobileScreen]);

  const handleOnChange = (state: boolean): void => {
    if (onChange) {
      onChange(state);
    }
    setCheckboxState(state);
  };

  const handleOnModalButtonClick = (): void => {
    if (onChange) {
      onChange(!checkboxState);
    }
    setCheckboxState(!checkboxState);
    setIsModalVisible(false);
  };

  return (
    <div className={className}>
      <StyledCheckbox
        className={classNames({
          error: validation?.type === 'ERROR',
        })}
        onChange={(checkboxValue: boolean): void => handleOnChange(checkboxValue)}
        value={value}
      />
      <div className="description">
        <div>{ `${description}${requiredMark ? '*' : ''} ` }</div>
        { isMobileScreen
          ? (
            <span
              className="agreement"
              onClick={(): void => setIsModalVisible(true)}
            >
              { agreement?.label }
            </span>
          )
          : (
            <span
              className="agreement"
              data-for="checkbox-tooltip"
              data-tip={`${agreement?.content}${requiredMark ? '*' : ''}`}
            >
              { agreement?.label }
            </span>
          ) }
      </div>
      { !isMobileScreen && agreement && (
        <ReactTooltip
          className="tooltip"
          delayShow={10}
          effect="solid"
          id="checkbox-tooltip"
          place="top"
        />
      ) }
      { portalExist && agreement && (
        <Modal
          buttonText={t('common:i-agree')}
          onClose={(): void => setIsModalVisible(false)}
          onModalButtonClick={!checkboxState ? handleOnModalButtonClick : undefined}
          open={isModalVisible && isMobileScreen}
          text={agreement?.content || ''}
          title={!checkboxState ? description : undefined}
          withButton={!checkboxState}
        />
      ) }
    </div>
  );
};

const StyledCheckboxWithDescription = styled(CheckboxWithDescription)`
  display: flex;
  margin-left: -40px;

  .tooltip {
    margin-left: 86px !important;
    margin-top: 2px !important;
    background-color: ${COLORS.TOOLTIP_BACKGROUND};
    color: ${COLORS.TOOLTIP_TEXT};
    font-size: 12px;
    line-height: 16px;
    font-family: "Open Sans";
    padding: 12px 16px;
    max-width: 232px;
    opacity: 1;

    &::before,&::after {
      display: none;
    }
  }

  .checkbox {
    margin-right: 19px;
    margin-top: 2px;
  }

  .validation {
    display: flex;
    align-items: center;
    margin-left: -2px;
    margin-top: 4px;
    height: 24px;

    .error-message {
      margin-left: 4px;
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
      color: ${COLORS.ERROR_MESSAGE};
      text-align: left;
    }
  }

  .description {
    font-size: 12px;
    line-height: 18px;
    max-width: 262px;
    color: ${COLORS.FORM.CHECKBOX_DESCRIPTION};
    white-space: pre-line;

    @media (max-width: 575px) {
      font-size: 10px;
      line-height: 14px;
    }

    .agreement {
      text-decoration: underline;
      cursor: pointer;

      :hover {
        color: ${COLORS.TOOLTIP_HOVER};
      }
    }
  }
`;

export default StyledCheckboxWithDescription;
