import { navigate } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS, UNITS } from '@/constants';
import { Detail, StyledArrowBox } from '@/components';
import { ProductKind, FormData } from '@/interfaces';
import { formatAsNumeric } from '@/utils/formatter';

const SummaryHeaderContent = styled.div`
  margin-bottom: 40px;
  
  > .section-container {
    background-color: ${COLORS.INVESTMENT_FORM_BACKGROUND};
    padding: 120px 138px 140px 64px;
    
    @media (max-width: 1399px) {
      padding: 100px 138px 115px 64px;
    }

    @media (max-width: 991px) {
      padding: 140px 36px 80px 36px;
    }
  }

  .container {
    @media (max-width: 991px) {
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
    }
  }

  .summary-header {
    @media (max-width: 991px) {
      font-weight: bold;
    }

    @media (max-width: 767px) {
      margin-bottom: 34px;
    }
  }

  .paragraph {
    max-width: 536px;
    width: 100%;
  }

  .separator {
    border: none;
    height: 1px;
    margin: 64px 0 44px 74px;
    background-color: ${COLORS.SUMMARY_SEPARATOR};
    color: ${COLORS.SUMMARY_SEPARATOR};

    @media (max-width: 1399px) {
      margin: 54px 0 34px 74px;
    }

    @media (max-width: 991px) {
      margin-left: 0;
    }

    @media (max-width: 767px) {
      margin-top: 40px;
      margin-bottom: 34px;
    }
  }

  .styled-arrow-box-back {
    background-color: unset;
    height: 40px;
    width: 40px;
    margin-top: 4px;

    @media (max-width: 991px) {
      display: none;
    }

    &:hover {
      .arrow-icon {
        #arrow {
          stroke: ${COLORS.BUTTON_SECONDARY_HOVER};
        }
      }  
    }

    .arrow-icon {
      #arrow {
        stroke: ${COLORS.COLOR_SECONDARY};
      }
    }
  }

  .heading {
    margin-left: 34px;

    @media (max-width: 991px) {
      margin-left: 0;
    }
  }

  .top-section {
    display: flex;
  }
`;

const InvestmentDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 260px));
  grid-row-gap: 44px;
  grid-column-gap: 16px;
  margin-left: 74px;

  @media (max-width: 1399px) {
    grid-template-columns: repeat(4, minmax(204px, 260px));
  }

  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, minmax(45%, 50%));
  }

  @media (max-width: 991px) {
    margin-left: 0;
  }

  @media (max-width: 767px) {
    grid-column-gap: 10px;
    grid-row-gap: 24px;
  }

  @media (max-width: 374px) {
    grid-template-columns: 100%;
  }
`;

interface SummaryHeaderProps {
  productKind: ProductKind;
  data: FormData;
}

const SummaryHeader = ({ data, productKind }: SummaryHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  const getInvestmentStatus = (): string => {
    if (data.status === 'UNDER_CONSTRUCTION') {
      return 'investmentForm:investment-phases.under-construction';
    }
    return 'investmentForm:investment-phases.built';
  };

  const getInvestmentLocalization = (): string => {
    if (data.localization === 'OTHER') {
      return 'investmentForm:other';
    }
    return data.localization;
  };

  const backToMain = (): void => {
    navigate('/');
  };

  const setLeaseRateUnit = (): string => {
    if (data.currency === UNITS.PLN) {
      return UNITS.PLN_PER_QUADRAMETER;
    }
    return UNITS.EUR_PER_QUADRAMETER;
  };

  const getYearOfConstructionLabel = (): string => {
    if (data.status === 'UNDER_CONSTRUCTION') {
      return 'investmentForm:year-of-construction';
    }
    return 'investmentForm:year-of-permission';
  };

  return (
    <SummaryHeaderContent>
      <div className="section-container">
        <div className="container">
          <div className="top-section">
            <StyledArrowBox
              className="styled-arrow-box-back"
              color="secondary"
              direction="left"
              onClick={backToMain}
            />
            <div className="heading">
              <h2 className="summary-header">{ t('summary:investment-details.title') }</h2>
              <p className="paragraph">{ t('summary:investment-details.sub-title') }</p>
            </div>
          </div>
          <hr className="separator" />
          <InvestmentDetails>
            <Detail
              contentText={productKind?.label}
              title="investmentForm:product-kind"
            />
            <Detail
              contentText={getInvestmentStatus()}
              title="investmentForm:status"
            />
            <Detail
              contentText={formatAsNumeric(data.estatePrice)}
              title="investmentForm:estate-price"
              unit={UNITS.PLN}
            />
            <Detail
              contentText={formatAsNumeric(data.area)}
              title="investmentForm:area"
              unit={UNITS.QUADRAMETER}
            />
            <Detail
              contentText={data.currency}
              title="investmentForm:currency"
            />
            <Detail
              contentText={data.leaseRate}
              title="investmentForm:lease-rate"
              unit={setLeaseRateUnit()}
            />
            <Detail
              contentText={data.serviceFee}
              title="investmentForm:service-fee"
              unit={UNITS.PLN_PER_QUADRAMETER}
            />
            <Detail
              contentText={data.yearOfConstruction}
              title={getYearOfConstructionLabel()}
            />
            <Detail
              contentText={getInvestmentLocalization()}
              title="investmentForm:localization"
            />
            {data.link && (
              <Detail
                contentText={data.link}
                title="investmentForm:link"
              />
            )}
          </InvestmentDetails>
        </div>
      </div>
    </SummaryHeaderContent>
  );
};

export default SummaryHeader;
