const currencies: Array<{value: string, label: string}> = [
  {
    value: 'PLN',
    label: 'PLN',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
];

export default currencies;
