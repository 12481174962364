import React from 'react';
import styled from 'styled-components';

interface HeaderAndContentProps {
  title: string;
  content: string;
}

const HeaderAndContentLayout = styled.div`
  .header-and-content-paragraph {
    margin-bottom: 64px;
  }
`;

const HeaderAndContent = ({ title, content }: HeaderAndContentProps): JSX.Element => (
  <HeaderAndContentLayout className="header-and-content">
    <h2 className="header-and-content-title">{ title }</h2>
    <p className="header-and-content-paragraph">{ content }</p>
  </HeaderAndContentLayout>
);

export default HeaderAndContent;
