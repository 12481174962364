export default {
  TEXT_SECONDARY: '#1c1c1c',
  TEXT_PRIMARY: '#ffffff',
  TEXT_BUTTON_SECONDARY: '#6f6f6f',
  COLOR_PRIMARY: '#f46f0f',
  COLOR_SECONDARY: '#552583',
  INVESTMENT_FORM_BACKGROUND: '#f8f8f8',
  FORM: {
    TEXT: '#29292c',
    PLACEHOLDER: '#6f6f6f',
    BORDER: '#d5d5d5',
    BORDER_FOCUSED: '#552583',
    SELECT_CHEVRON: '#552583',
    UNIT: '#b4b4b4',
    CARET: '#959595',
    SELECT_BACKGROUND: '#ffffff',
    SELECT_HOVER: '#f8f8f8',
    SELECT_OPTIONS_SHADOW: 'rgba(0, 0, 0, 0.08)',
    CHECKBOX_DESCRIPTION: '#c299e8',
    CHECKBOX_BORDER: '#c299e8',
    CHECKBOX_BORDER_HOVER: '#ffffff',
    CHECKBOX_ERROR_BORDER: '#eb002d',
    CHECKBOX_WHITE_BACKGROUND: '#ffffff',
    CHECKBOX_VIOLET_CHECKMARK: '#552583',
    BUTTON: '#c299e8',
    BUTTON_TEXT: '#552583',
    BUTTON_ERROR_SUCCESS_TEXT: '#ffffff',
    BUTTON_HOVER: '#ffffff',
    BUTTON_ERROR: '#eb002d',
    BUTTON_SUCCESS: '#09bf4d',
    INPUT_LABEL: '#c299e8',
    INPUT_BORDER: '#431d69',
    INPUT_CARET: '#a3a3a3',
    INPUT_FOCUS: '#ffffff',
    ERROR_MESSAGE: '#ffffff',
  },
  BUTTON_PRIMARY_HOVER: '#f77f29',
  BUTTON_SECONDARY_HOVER: '#6c379f',
  NAVIGATION_BAR_BACKGROUND: '#ffffff',
  FOOTER_BACKGROUND: '#431d69',
  FOOTER_MAIN_COLOR: '#c299e8',
  FOOTER_HOVER: '#ffffff',
  SHADOWS: {
    TOOLTIP_MESSAGE: '0 0 12px 0 rgba(0, 0, 0, 0.08)',
    COOKIE_BAR: '0 0 12px 0 rgba(0, 0, 0, 0.16)',
    BANNER_HEADER: '0 2px 4px rgba(0, 0, 0, 0.4)',
    BANNER_PARAGRAPH: '0 2px 4px rgba(0, 0, 0, 0.4)',
  },
  TOOLTIP_TEXT: '#6f6f6f',
  TOOLTIP_HOVER: '#ffffff',
  ERROR_MESSAGE: '#eb002d',
  TOOLTIP_BACKGROUND: '#ffffff',
  ERROR_PAGE_BACKGROUND: '#f8f8f8',
  PRODUCT_HOVER_BORDER: '#d5d5d5',
  SUMMARY_SEPARATOR: '#e6e6e6',
  SUMMARY_CHECKBOX_BORDER: '#d5d5d5',
  SUMMARY_CHECKBOX_CHECKED: '#969696',
  FORM_BUTTON_SUCCESS: '#09bf4d',
  COOKIE_BAR_CONTENT: '#552583',
  COOKIE_BAR_DESCRIPTION: '#c299e8',
  COOKIE_POLICY_HOVER: '#ffffff',
  COOKIE_PRIMARY_BUTTON: '#c299e8',
  COOKIE_SECONDARY_BUTTON: '#552583',
  COOKIE_PRIMARY_BUTTON_HOVER: '#ffffff',
  COOKIE_SECONDARY_BUTTON_HOVER: '#6c379f',
  MODAL_TITLE: '#000000',
  NAVIGATION_BAR_BUTTON: {
    BLACK: '#000000',
    WHITE: '#ffffff',
    GRAY: '#6f6f6f',
  },
  DETAILS_SEPARATOR: '#e6e6e6',
  EXAMPLE_OFFERS_DISCLAIMER: '#6f6f6f',
  PROCESSING_CONTAINER: '#f8f8f8',
  PROCESSING_DESCRIPTION: '#6f6f6f',
  PROGRESS_BAR_FILLER: 'linear-gradient(to left, #fec300 50%, #f46f0f)',
  PROGRESS_BAR_BACKGROUND: '#e1e1e1',
};
