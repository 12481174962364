import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { BankBanner, BankBannerRetina, BankBannerBlurred, BankBannerRetinaBlurred } from '@/assets/images';
import { StyledButtonWithArrow } from '@/components';
import { COLORS } from '@/constants';
import { applyRetinaImage } from '@/utils/retina';

const BanksBannerContainer = styled.div<{ hasPlaceholder: boolean, hasPlaceholderRetina: boolean }>`
  height: 100%;
  position: relative;
  background-image: url('${BankBannerBlurred}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;

  ${(props): FlattenSimpleInterpolation => (props.hasPlaceholderRetina
    ? applyRetinaImage(BankBannerRetinaBlurred)
    : applyRetinaImage(BankBannerRetina))};

  @media (min-width: 992px) {
    min-height: 768px;
  }

  @media (max-width: 991px) {
    background-position-x: 38%;
  }

  @media (max-width: 575px) {
    background-position-x: 47%;
  }

  .section-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('${BankBanner}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;

    ${applyRetinaImage(BankBannerRetina)}

    opacity: ${(props): number => (props.hasPlaceholder ? 0 : 1.0)};

    @media 
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
      opacity: ${(props): number => (props.hasPlaceholderRetina ? 0 : 1.0)};
    }

    transition: opacity 0.3s;

    @media (max-width: 991px) {
      background-position-x: 38%;
    }

    @media (max-width: 575px) {
      background-position-x: 47%;
    }
  }

  > .section-container {
    height: 100%;
    padding-left: 138px;
    position: relative;
  }

  .content {
    position: relative;
    top: 26%;
    color: ${COLORS.TEXT_SECONDARY};
    max-width: 515px;
    z-index: 10;
  }

  .content-header {
    font-weight: bold;
  }

  .button-learn-more {
    margin-top: 100px;
    display: inline-flex;
  }

  @media (max-width: 1399px) {
    .content {
      max-width: 450px;
    }
  }

  @media (max-width: 1199px) {
    > .section-container {
      padding-left: 70px;
    }
  }

  @media (max-width: 991px) {
    > .section-container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: 524px;
    }
  
    .content {
      max-width: 524px;
      padding-left: 36px;
      padding-right: 36px;
    }

    .content-header {
      font-size: 2.6rem;
      max-width: 425px;
    }

    p {
      width: unset;
    }
  }

  @media (max-width: 767px) {
    .content-header {
      font-size: 2.3rem;
      max-width: 378px;
    }
  }

  @media (max-width: 575px) {
    > .section-container {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
      position: relative;
    }

    .content {
      padding-top: 140px;
      top: 0;
      max-width: 100%;
      padding-left: 36px;
      padding-right: 36px;
      width: 353px;
      height: 100%;
    
      .button-learn-more {
        margin-top: 0;
        position: absolute;
        left: 36px;
        bottom: 40px;

        .button {
          display: none;
        }

        .arrow-box {
          margin-left: 0;
        }
      }
    }
    
    .content-header {
      font-size: 1.71rem;
      margin-bottom: 2.42rem;
      width: 252px;
      max-width: 100%;
    }
  }
`;

const BanksBanner = (): JSX.Element => {
  const { t } = useTranslation();
  const [hasPlaceholder, setHasPlaceholder] = useState(true);
  const [hasPlaceholderRetina, setHasPlaceholderRetina] = useState(true);

  useEffect(() => {
    const bannerImage = new Image();
    const bannerImageRetina = new Image();

    bannerImage.onload = (): void => {
      setHasPlaceholder(false);
    };

    bannerImageRetina.onload = (): void => {
      setHasPlaceholderRetina(false);
    };

    bannerImage.src = BankBanner;
    bannerImageRetina.src = BankBannerRetina;
  }, []);

  return (
    <BanksBannerContainer
      hasPlaceholder={hasPlaceholder}
      hasPlaceholderRetina={hasPlaceholderRetina}
    >
      <div className="section-background" />
      <div className="section-container">
        <div className="content">
          <h1 className="content-header">{ t('banks:banner.title') }</h1>
          <p>{ t('banks:banner.description') }</p>
          <StyledButtonWithArrow
            className="button-learn-more"
            color="secondary"
            direction="down"
            onClick={(): void => { scrollTo('#banks-left-sided', 'start'); }}
            title={t('common:button.learn-more')}
          />
        </div>
      </div>
    </BanksBannerContainer>
  );
};

export default BanksBanner;
