const statuses: Array<{value: string, label: string}> = [
  {
    value: 'UNDER_CONSTRUCTION',
    label: 'investmentForm:investment-phases.under-construction',
  },
  {
    value: 'BUILT',
    label: 'investmentForm:investment-phases.built',
  },
];

export default statuses;
