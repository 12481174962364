import styled from 'styled-components';

import { COLORS } from '@/constants';
import { Color } from '@/interfaces';

type ButtonProps = Color

const Button = styled.div<ButtonProps>`
  padding: 20px 22px;
  background-color: ${({ color }): string => (color === 'primary' ? COLORS.COLOR_PRIMARY : COLORS.COLOR_SECONDARY)};
  color: ${COLORS.TEXT_PRIMARY};
  width: fit-content;

  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.11px;
`;

export default Button;
