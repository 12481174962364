import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Detail from '@/components/Detail';
import { COLORS } from '@/constants';

interface OfferProps {
  interestRate: string;
  ltv: string;
  ownContribution: string;
  preparationFee: string;
  rentalLevel: string;
  bankNumber: number;
}

const OfferContainer = styled.div`
  min-height: 260px;
  background-color: #fff7f7;
  padding: 44px 138px 44px 130px;
  border-left: 8px solid #f46f0f;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    padding-left: 28px;
    padding-right: 36px;
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .content {
    @media (max-width: 991px) {
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
    }
  }

  h3 {
    font-size: 2.125rem;
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  .separator {
    border: none;
    height: 1px;
    background-color: #f7d5c2;
    color: #f7d5c2;
    margin: 24px 0;

    @media (max-width: 767px) {
      margin-bottom: 35px;
    }
  }

  .detail-separator {
    display: none;
    border: none;
    height: 1px;
    background-color: ${COLORS.DETAILS_SEPARATOR};
    color: ${COLORS.DETAILS_SEPARATOR};
    margin: 16px 0 26px 0;
  }

  .offer-content {
    display: grid;
    grid-template-columns: repeat(5, minmax(100px, 260px));
    grid-row-gap: 44px;
    grid-column-gap: 16px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(3, minmax(30%, 33.3333%));
    }

    @media (max-width: 575px) {
      display: block;
    }
  }
`;

const Offer = ({
  bankNumber,
  interestRate,
  ltv,
  ownContribution,
  preparationFee,
  rentalLevel,
}: OfferProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <OfferContainer>
      <div className="content">
        <h3>{ `${t('common:bank')} ${bankNumber}` }</h3>
        <hr className="separator" />
        <div className="offer-content">
          <Detail
            contentText={`${t('common:from')} ${ownContribution}%`}
            title="investmentForm:bank-offer.own-contribution"
          />
          <hr className="detail-separator" />
          <Detail
            contentText={`${t('common:from')} ${rentalLevel}%`}
            title="investmentForm:bank-offer.rental-level"
          />
          <hr className="detail-separator" />
          <Detail
            contentText={`${t('common:to')} ${ltv}%`}
            title="investmentForm:bank-offer.ltv"
          />
          <hr className="detail-separator" />
          <Detail
            contentText={`${t('common:from')} ${interestRate}% ${t('common:p-a')}`}
            title="investmentForm:bank-offer.interest-rate"
          />
          <hr className="detail-separator" />
          <Detail
            contentText={`${t('common:from')} ${preparationFee}%`}
            title="investmentForm:bank-offer.preparation-fee"
          />
        </div>
      </div>
    </OfferContainer>
  );
};

export default Offer;
