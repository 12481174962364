import Arrow from './arrow-left.svg';
import CheckboxSelect from './checkbox_select.svg';
import Checkmark from './checkmark.svg';
import Chevron from './chevron.svg';
import ErrorIcon from './no.svg';
import ExclamationMark from './exclamation-mark.svg';
import Help from './help_center.svg';
import IconClose from './icon-close.svg';
import IconSelected from './icon-selected.svg';
import Logo from './logo-main.svg';
import LogoFooter from './logo-footer.svg';
import SuccessIcon from './yes.svg';
import SygnetFooter from './img-sygnet-footer.svg';
import MenuIcon from './icon-menu.svg';

export {
  Arrow,
  CheckboxSelect,
  Checkmark,
  Chevron,
  ErrorIcon,
  ExclamationMark,
  Help,
  IconClose,
  IconSelected,
  Logo,
  LogoFooter,
  SuccessIcon,
  SygnetFooter,
  MenuIcon,
};
