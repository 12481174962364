import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PersonNo } from '@/assets/images';
import { StyledButtonWithArrow } from '@/components';
import { COLORS } from '@/constants';
import { navigate } from 'gatsby';

const NoOffersPlaceholderLayout = styled.div`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-top: 40px;
  }

  > .section-container {
    background-color: ${COLORS.INVESTMENT_FORM_BACKGROUND};
    padding: 79px 200px 79px 138px;

    @media (max-width: 1679px) {
      padding-right: 138px;
    }

    @media (max-width: 1399px) {
      padding-top: 65px;
      padding-bottom: 65px;
    }

    @media (max-width: 1199px) {
      padding: 100px 138px 80px 138px;
    }

    @media (max-width: 991px) {
      padding-right: 36px;
      padding-left: 36px;
    }

    @media (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1199px) {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    @media (max-width: 991px) {
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .content {
    max-width: 674px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;

    @media (max-width: 1399px) {
      max-width: 455px;
    }

    @media (max-width: 1199px) {
      max-width: unset;
      width: 100%;
    }

    @media (max-width: 991px) {
      align-content: flex-start;
    }

    .content-text {
      @media (max-width: 991px) {
        align-self: flex-start;
        max-width: 548px;
      }

      h2 {
        @media (max-width: 991px) {
          font-weight: bold;
        }

        @media (max-width: 767px) {
          margin-bottom: 34px;
        }
      }
    }
  }

  .button-back {
    margin-top: 100px;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .button {
      font-size: 14px;

      @media (max-width: 374px) {
        height: 50px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 13px;
        padding-right: 13px;
        font-size: 12px;
      }
    }

    @media (max-width: 1399px) {
      margin-top: 80px;
    }

    @media (max-width: 1199px) {
      display: none;
      margin-top: 50px;
    }

    &.mobile {
      display: none;

      @media (max-width: 1199px) {
        display: flex;
      }
    }

    .arrow-box {
      margin: 0 2px 0 0;
      transform: rotate(180deg);

      @media (max-width: 374px) {
        height: 50px;
        width: 50px;
      }
    }
  }

  .image {
    width: 440px;
    height: 440px;
    background-image: url(${PersonNo});
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 100% auto;

    @media (max-width: 1399px) {
      width: 350px;
      height: 350px;
    }

    @media (max-width: 1199px) {
      margin-top: 50px;
    }

    @media (max-width: 767px) {
      width: 300px;
      height: 300px;
      max-width: 100%;
      max-height: 300px;
    }
  }

  .paragraph {
    max-width: 520px;
  }
`;

const NoOffersPlaceholder = (): JSX.Element => {
  const { t } = useTranslation();

  const handleOnClick = (): void => {
    navigate('/investment');
  };

  return (
    <NoOffersPlaceholderLayout>
      <div className="section-container">
        <div className="container">
          <div className="content">
            <div className="content-text">
              <h2 className="content-text--header">{ t('common:no-bank-offers-title') }</h2>
              <p className="paragraph">{ t('common:no-bank-offers-subtitle') }</p>
            </div>
            <StyledButtonWithArrow
              className="button-back"
              color="secondary"
              direction="right"
              onClick={handleOnClick}
              title={t('common:button.change-criteria')}
            />
          </div>
          <div className="image" />
          <StyledButtonWithArrow
            className="button-back mobile"
            color="secondary"
            direction="right"
            onClick={handleOnClick}
            title={t('common:button.change-criteria')}
          />
        </div>
      </div>
    </NoOffersPlaceholderLayout>
  );
};

export default NoOffersPlaceholder;
