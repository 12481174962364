import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS } from '@/constants';

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="section-container">
        <div className="left">
          <span className="cookies">{ t('footer:regulations') }</span>
          <span className="policy">{ t('footer:rodo') }</span>
          <span className="cookies">{ t('footer:contact') }</span>
          <span className="policy">{ t('footer:policy') }</span>
        </div>
        <div className="right">
          <span className="line" />
          <span className="signature">{ t('footer:signature') }</span>
        </div>
      </div>
    </div>
  );
};

const StyledFooter = styled(Footer)`
  background-color: ${COLORS.FOOTER_BACKGROUND};
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.FOOTER_MAIN_COLOR};

  > .section-container {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    place-items: center;

    @media (max-width: 1199px) {
      flex-direction: column;
      min-height: unset;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @media (max-width: 575px) {
      padding-top: 34px;
    }
  }

  .signature {
    margin-left: 16px;
    min-width: 260px;

    @media (max-width: 1199px) {
      margin-left: 0;
    }
  }

  .left {
    display: flex;
    justify-content: space-between;
    place-items: center;

    @media (max-width: 1199px) {
      justify-content: center;
      width: 100%;
    }

    @media (max-width: 575px) {
      max-width: 303px;
      flex-wrap: wrap;
    }

    .half {
      flex-grow: 1;
      display: flex;
      justify-content: space-around;
    }

    span {
      width: 122px;
      cursor: pointer;
      margin-right: 16px;

      @media (max-width: 1399px) {
        width: 105px;
      }

      @media (max-width: 1199px) {
        width: 146px;
      }

      @media (max-width: 767px) {
        width: 110px;
      }

      @media (max-width: 575px) {
        min-width: 127px;
        max-width: 151px;
        width: 50%;
        margin-bottom: 24px;
        margin-right: 0;
        padding-right: 5px;
      }

      &:last-of-type {
        @media (max-width: 1199px) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @media (max-width: 575px) {
          padding-right: 0;
          padding-left: 5px;
        }
      }

      :hover {
        color: ${COLORS.FOOTER_HOVER};
      }
    }
  }

  .right {
    display: flex;
    flex-grow: 1;

    @media (max-width: 1199px) {
      justify-content: center;
      flex-direction: column;
      width: 100%;
      flex-grow: unset;
    }
  }

  .line {
    margin-top: 7px;
    height: 2px;
    background-color: ${COLORS.FOOTER_MAIN_COLOR};
    flex-grow: 1;

    @media (max-width: 1199px) {
      flex-grow: unset;
      margin-bottom: 24px;
      margin-top: 24px;
      width: 632px;
      align-self: center;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 504px;
    }

    @media (max-width: 575px) {
      margin-top: 10px;
      width: 100%;
      max-width: 303px;
    }
  }
`;

export default StyledFooter;
