/* eslint-disable indent */
import { navigate } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import StyledButtonsSection, { NAVIGATIONBAR_BUTTON_TYPE } from './ButtonsSection';
import StyledMobileMenu from './MobileMenu';
import { Logo, MenuIcon } from '@/assets/svgs';
import { COLORS } from '@/constants';

interface NavigationBarProps {
  className?: string;
  initialValue: NAVIGATIONBAR_BUTTON_TYPE;
  isClientPage?: boolean;
  children?: React.ReactNode;
  darkMenuIcon?: boolean;
}

const NavigationBar = ({ className, initialValue, children }: NavigationBarProps): JSX.Element => {
  const [navBackground, setNavBackground] = useState('transparent');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeButtonType, setActiveButtonType] = useState<NAVIGATIONBAR_BUTTON_TYPE>(initialValue);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const [isBodyScrolled, setIsBodyScrolled] = useState(false);

  const showDialog = (): void => {
    setIsMobileMenuOpen(true);
    document.getElementById('dialog')?.classList.add('show');
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const { body } = document;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
    setIsBodyScrolled(!!parseFloat(scrollY));
  };

  const closeDialog = (): void => {
    setIsMobileMenuOpen(false);
    const { body } = document;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    if (window) {
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
    document.getElementById('dialog')?.classList.remove('show');
  };

  const handleOnButtonClick = (type: NAVIGATIONBAR_BUTTON_TYPE): void => {
    setActiveButtonType(type);
    if (isMobileMenuOpen) {
      closeDialog();
    }

    switch (type) {
      case 'FOR_CLIENT':
        if (initialValue === 'FOR_CLIENT') {
          scrollTo('#banner');
        } else {
          navigate('/');
        }
        break;
      case 'FOR_BANK':
        if (initialValue === 'FOR_BANK') {
          scrollTo('#banner');
        } else {
          navigate('/banks');
        }
        break;
      case 'CONTACT':
        scrollTo('#contact');
        break;
      case 'LOGIN':
        if (window) {
          window.location.replace('https://app.fintelli.pl');
        }
        break;
      default:
        break;
    }
  };

  const handleScroll = (): void => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    if (window.scrollY > 50) {
      setNavBackground('white');
    } else {
      setNavBackground('transparent');
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
    }
    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOnLogoClick = (): void => {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname === '/') {
      scrollTo('#index-banner', 'start');
    } else {
      navigate('/');
    }
  };

  const handleResize = (): void => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
    }
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={className}>
      { isMobileMenuOpen
        ? (
          <StyledMobileMenu
            className={`${!isMobileMenuOpen ? '--hidden' : ''}${isBodyScrolled ? ' navbar-fixed' : ''}`}
            initialValue={activeButtonType}
            onButtonClick={handleOnButtonClick}
            onClose={closeDialog}
          />
        )
        : (
          <div className={`navbar
             ${navBackground === 'transparent' ? '--transparent' : '--white'}
             ${windowWidth <= 991 ? ' mobile' : ''}`}
          >
            <div className="section-container">
              <Logo
                className="logo"
                onClick={handleOnLogoClick}
              />
              <StyledButtonsSection
                initialValue={activeButtonType}
                isSubmitVisible={navBackground !== 'transparent'}
                onClick={handleOnButtonClick}
              />
              <MenuIcon
                className="menu-icon"
                onClick={(): void => showDialog()}
              />
            </div>
          </div>
        ) }
      <div className={`page ${isMobileMenuOpen ? '--hidden' : ''}`}>{ children }</div>
    </div>
  );
};

const StyledNavigationBar = styled(NavigationBar)`

${StyledMobileMenu} {
  position: fixed;
  top: 0;
  z-index: 10000000;

  &.--hidden {
    display: none;
  }
}

.page {
  color: yellow;

  &.--hidden {
  }
}

.navbar {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 1000000000;

  > .section-container {
    display: flex;
    justify-content: space-between;
    place-items: center;
    height: 100%;
  }

  .logo, .menu-icon {
    cursor: pointer;
  }

  &.fixed {
    position: fixed;
  }

  &.--transparent {
    background-color: transparent;
    ${({ isClientPage }): string => (isClientPage ? '#logo-main { #Group_1 { > * { fill: white }}}' : '')};

    .buttons-section {
      .nav-bar-button {
        .active {
          color: ${({ isClientPage }): string => (isClientPage
          ? `${COLORS.NAVIGATION_BAR_BUTTON.WHITE}`
          : `${COLORS.NAVIGATION_BAR_BUTTON.BLACK}`)}; 
        }

        .nav-bar-button--button {
          &:active {
            color: ${({ isClientPage }): string => (isClientPage
              ? `${COLORS.NAVIGATION_BAR_BUTTON.WHITE}`
              : `${COLORS.NAVIGATION_BAR_BUTTON.BLACK}`)};
          }

          &:hover {
            color: ${({ isClientPage }): string => (isClientPage
              ? `${COLORS.NAVIGATION_BAR_BUTTON.WHITE}`
              : `${COLORS.NAVIGATION_BAR_BUTTON.BLACK}`)};
          }
        }
      }
    }
  }

  &.--white {
    background-color: white;

    &.mobile {
      height: 64px;
    }

    .menu-icon {
      #Group_2419 > *{
        stroke: black;
      }
    }
  }

  .menu-icon {
    display: none;
  }

  @media (max-width: 991px) {
    ${StyledButtonsSection} {
      display: none;
    }

    .menu-icon {
      display: block;

      #Group_2419 > *{
        stroke: ${({ darkMenuIcon }): string => (darkMenuIcon ? 'black' : 'white')};
      }
    }
  }
}
`;

export default StyledNavigationBar;
