import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS } from '@/constants';

interface DetailProps {
  title: string;
  unit?: string;
  contentText?: string;
}

const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: unset;
  }

  .title {
    font-size: 12px;
    line-height: 1.67;
    color: ${COLORS.TEXT_BUTTON_SECONDARY};
  }

  .detail-content {
    margin-top: 4px;
    display: flex;

    .text, .unit {
      font-size: 20px;
      line-height: 1.3;
      color: ${COLORS.FORM.TEXT};

      @media (max-width: 1399px) {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .unit {
      margin-left: 8px;
    }
  }
`;

const Detail = ({ title, unit = '', contentText = '' }: DetailProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DetailContent className="detail-content">
      <div className="title">{ t(title) }</div>
      <div className="detail-content">
        <div className="text">{ t(contentText) }</div>
        <div className="unit">{ unit }</div>
      </div>
    </DetailContent>
  );
};

export default Detail;
