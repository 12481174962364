/* eslint-disable multiline-ternary */
/* eslint-disable indent */
import React from 'react';
import { Roller } from 'react-css-spinners';
import styled from 'styled-components';

import { Arrow } from '@/assets/svgs';
import { COLORS } from '@/constants';
import { Color, Direction } from '@/interfaces';

interface ArrowBoxProps extends Color, Direction {
  className?: string;
  loading?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
}

const ArrowBox = ({ className, loading = false, onClick, icon }: ArrowBoxProps): JSX.Element => (
  <div
    className={className}
    onClick={onClick}
  >
    {
      loading ? (
        // TODO: Add color const later
        <Roller
          color="#552583"
          size={40}
        />
      ) : (
        <>{ icon || <Arrow className="arrow-icon" /> }</>
      )
    }
  </div>
);

const StyledArrowBox = styled(ArrowBox)`
  width: 60px;
  height: 60px;
  display: flex;
  place-items: center;
  justify-content: center;
  background-color: ${({ color }): string => (color === 'primary' ? COLORS.COLOR_PRIMARY : COLORS.COLOR_SECONDARY)};
  cursor: pointer;

  .arrow-icon {
    #arrow {
      stroke: white;
    }

    transform: ${({ direction }): string => {
      switch (direction) {
        case 'down':
          return 'rotate(-90deg)';
        case 'up':
          return 'rotate(90deg)';
        case 'right':
          return 'rotate(180deg)';
        default:
          return 'rotate(0deg)';
      }
    }
  }
`;

export default StyledArrowBox;
