import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SubmitApplicationButton from '../Button/SubmitApplicationButton';
import {
  WhyFintelliBackground,
  WhyFintelliBackgroundMobile,
  WhyFintelliBackgroundRetina,
  WhyFintelliBackgroundRetinaMobile,
} from '@/assets/images';
import { HeaderAndContent } from '@/components';
import { applyRetinaImage } from '@/utils/retina';

const WhyFintelliContainer = styled.div`
  margin-bottom: 100px;
  position: relative;

  @media (max-width: 991px) {
    margin-bottom: 0;
  }

  > .section-container {
    padding-left: 828px;
    background-image: url('${WhyFintelliBackground}');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    ${applyRetinaImage(WhyFintelliBackgroundRetina)};

    @media (max-width: 1679px) {
      padding-left: 710px;
      background-position-x: -100px;
    }

    @media (max-width: 1399px) {
      padding-left: 610px;
    }

    @media (max-width: 1199px) {
      padding-left: 490px;
      background-position-x: -190px;
    }

    @media (max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
      background-position-x: 45%;
    }

    @media (max-width: 767px) {
      background-position-x: 52%;
    }

    @media (max-width: 575px) {
      background: none;
    }
  }
`;

const WhyFintelliImage = styled.div`
  display: none;
  background-image: url('${WhyFintelliBackgroundMobile}');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: left;
  width: 100%;
  height: 369px;
  ${applyRetinaImage(WhyFintelliBackgroundRetinaMobile)};

  @media (max-width: 575px) {
    display: block;
  }

  @media (max-width: 374px) {
    background-position-x: center;
    height: 370px;
  }
`;

const WhyFintelliContent = styled.div`
  padding: 190px 0;

  @media (max-width: 1399px) {
    padding: 150px 0;
  }

  @media (max-width: 1199px) {
    padding: 130px 70px 130px 0;
  }

  @media (max-width: 991px) {
    padding-left: 55%;
    padding-top: 115px;
    padding-bottom: 115px;
  }

  @media (max-width: 767px) {
    padding-top: 44px;
    padding-bottom: 44px;
    padding-left: 50%;
    padding-right: 36px;
  }

  @media (max-width: 575px) {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 0;
    padding-bottom: 20px;
  }

  p {
    line-height: 1.5rem;
  }

  .why-fintelli-text {
    max-width: 525px;

    @media (max-width: 1399px) {
      max-width: 455px;
    }

    @media (max-width: 575px) {
      max-width: unset;
    }
  }
  
  .submit-button {
    margin-top: 100px;

    @media (max-width: 1399px) {
      margin-top: 80px;
    }

    @media (max-width: 767px) {
      margin-top: 44px;
    }

    .button {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.11px;
    }
  }

  .header-and-content-title {
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  .header-and-content-paragraph {
    margin-bottom: 1.5rem;
  }

  .why-fintelli-text-paragraph {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const WhyFintelli = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <WhyFintelliContainer className="why-fintelli-container">
      <div className="section-container">
        <WhyFintelliContent className="why-fintelli-content">
          <div className="why-fintelli-text">
            <HeaderAndContent
              content={t('whyFintelli:description.first')}
              title={t('whyFintelli:title')}
            />
            <p className="why-fintelli-text-paragraph">{ t('whyFintelli:description.second') }</p>
          </div>
          <SubmitApplicationButton className="submit-button" />
        </WhyFintelliContent>
        <WhyFintelliImage className="why-fintelli-image" />
      </div>
    </WhyFintelliContainer>
  );
};

export default WhyFintelli;
