import BankBanner from './img_dlabanku-001.jpg';
import BankBannerBlurred from './img_dlabanku-001-low-blur.jpg';
import BankBannerRetina from './img_dlabanku-001@2x.jpg';
import BankBannerRetinaBlurred from './img_dlabanku-001@2x-low-blur.jpg';
import ClientBanner from './img_banner.jpg';
import Favicon from './favicon.ico';
import FooterImage from './img-sygnet-footer.png';
import ForBank from './img_dlabanku-002.jpg';
import ForBankRetina from './img_dlabanku-002@2x.jpg';
import ForClientFirst from './img_dlaklienta-001.jpg';
import ForClientFirstRetina from './img_dlaklienta-001@2x.jpg';
import ForClientSecond from './img_dlaklienta-002.jpg';
import ForClientSecondRetina from './img_dlaklienta-002@2x.jpg';
import ForClientThird from './img_dlaklienta-003.jpg';
import ForClientThirdRetina from './img_dlaklienta-003@2x.jpg';
import PersonNo from './img-no.png';
import PersonNoRetina from './img-no@2x.png';
import PersonYes from './img-yes.png';
import PersonYesRetina from './img-yes@2x.png';
import WhyFintelliBackground from './img_dlaczego.jpg';
import WhyFintelliBackgroundMobile from './img_dlaczego-mobile.png';
import WhyFintelliBackgroundRetina from './img_dlaczego@2x.jpg';
import WhyFintelliBackgroundRetinaMobile from './img_dlaczego-mobile@2x.png';
import VideoGradient from './video-gradient.png';
import VideoPlaceholder from './video-placeholder.jpg';
import MenuImage from './img-sygnet_menu.png';

import BusinessPremise from './icon_lokale@2x.png';
import DeveloperProjects from './icon_projektydew@2x.png';
import Office from './icon_biura@2x.png';
import Photovoltaics from './icon_fotowoltaika@2x.png';
import RentalApartments from './icon_mieszkania@2x.png';
import Residence from './icon_akademiki@2x.png';
import ShoppingMall from './icon_galerie@2x.png';
import ShoppingPark from './icon_parkihandlowe@2x.png';
import Warehouse from './icon_magazyny@2x.png';

export {
  BankBanner,
  BankBannerBlurred,
  BankBannerRetina,
  BankBannerRetinaBlurred,
  ClientBanner,
  Favicon,
  FooterImage,
  ForBank,
  ForBankRetina,
  ForClientFirst,
  ForClientFirstRetina,
  ForClientSecond,
  ForClientSecondRetina,
  ForClientThird,
  ForClientThirdRetina,
  PersonNo,
  PersonNoRetina,
  PersonYes,
  PersonYesRetina,
  WhyFintelliBackground,
  WhyFintelliBackgroundMobile,
  WhyFintelliBackgroundRetina,
  WhyFintelliBackgroundRetinaMobile,
  VideoGradient,
  VideoPlaceholder,
  MenuImage,
  BusinessPremise,
  DeveloperProjects,
  Office,
  Photovoltaics,
  RentalApartments,
  Residence,
  ShoppingMall,
  ShoppingPark,
  Warehouse,
};
