import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS } from '@/constants';
import { Offer } from '@/components';
import { IOffer } from '@/interfaces';

const ExampleOffersLayout = styled.div`
  margin-bottom: 40px;

  > .section-container {
    @media (max-width: 991px) {
      padding: 0;
    }
  }

  .header {
    display: flex;
    align-items: flex-start;
    padding: 40px 138px;

    @media (max-width: 991px) {
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      max-width: unset;
      padding-left: 36px;
      padding-right: 36px;
    }

    .header-sub-title {
      margin-left: 16px;
      font-size: 12px;
      color: ${COLORS.EXAMPLE_OFFERS_DISCLAIMER};

      @media (max-width: 991px) {
        margin-left: 0;
        line-height: 20px;
      }
    }

    .header-title {
      width: 536px;
      max-width: 100%;
      margin-bottom: 0;
      padding-right: 85px;
      flex-shrink: 0;

      @media (max-width: 1399px) {
        padding-right: 0;
        width: 395px;
      }

      @media (max-width: 1199px) {
        width: 335px;
      }

      @media (max-width: 991px) {
        width: unset;
        font-weight: bold;
        margin-bottom: 2.125rem;
      }

      @media (max-width: 767px) {
        margin-bottom: 34px;
      }
    }
  }

  .offers {
    margin-top: 24px;

    @media (max-width: 767px) {
      margin-top: 0;
    }

    .offer-content {
      > .detail-content {
        @media (max-width: 575px) {
          padding-top: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid ${COLORS.SUMMARY_SEPARATOR};
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }

        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
`;

interface ExampleOffersProps {
  offers: IOffer[]
}

const ExampleOffers = ({ offers }: ExampleOffersProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ExampleOffersLayout>
      <div className="section-container">
        <div className="header">
          <h2 className="header-title">{ t('summary:example-offers.title') }</h2>
          <p className="header-sub-title">{ t('summary:example-offers.sub-title') }</p>
        </div>
        <div className="offers">
          {
            offers.map((offer: IOffer, index: number) => (
              <Offer
                bankNumber={index + 1}
                interestRate={offer.interestRate}
                ltv={offer.ltv}
                ownContribution={offer.ownContribution}
                preparationFee={offer.preparationFee}
                rentalLevel={offer.rentalLevel}
              />
            ))
          }
        </div>
      </div>
    </ExampleOffersLayout>
  );
};

export default ExampleOffers;
