import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CheckboxSelect } from '@/assets/svgs';
import { COLORS } from '@/constants';

interface CheckboxPros {
  className?: string;
  onChange?: (value: boolean) => void;
  value?: boolean;
}

const Checkbox = ({ className, onChange, value = false }: CheckboxPros): JSX.Element => {
  const [isChecked, setIsChecked] = useState(value);

  const handleCheckboxChange = (): void => {
    if (onChange) {
      onChange(!isChecked);
    }
    setIsChecked((previousState) => !previousState);
  };

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <div
      className={`${className} checkbox ${isChecked ? '--checked' : ''}`}
      onClick={handleCheckboxChange}
    >
      { isChecked && <CheckboxSelect /> }
    </div>
  );
};

const StyledCheckbox = styled(Checkbox)`
  min-width: 20px;
  height: 20px;

  border: 2px solid ${COLORS.FORM.CHECKBOX_BORDER};
  border-radius: 2px;

  position: relative;

  #select {
    position: absolute;
    top: -4px;
    left: -4px;
  }

  :hover {
    border-color: ${COLORS.FORM.CHECKBOX_BORDER_HOVER};
  }

  &.--checked {
    background-color: ${COLORS.FORM.CHECKBOX_BORDER_HOVER};
  }

  &.error {
    border: 2px solid ${COLORS.FORM.CHECKBOX_ERROR_BORDER};
  }
`;

export default StyledCheckbox;
