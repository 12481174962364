import { COLORS } from '@/constants';
import { Color } from '@/interfaces';

import styled from 'styled-components';

const CookieBarButton = styled.div<Color>`
  padding: 20px 22px;
  color: ${({ color }): string => (color === 'primary' ? COLORS.COOKIE_BAR_CONTENT : COLORS.COOKIE_BAR_DESCRIPTION)};
  background-color: ${({ color }): string => (
    color === 'primary'
      ? COLORS.COOKIE_PRIMARY_BUTTON
      : COLORS.COOKIE_SECONDARY_BUTTON
  )};
  ${({ color }): string => (color === 'secondary' ? `border-left: 1px solid ${COLORS.COOKIE_BAR_DESCRIPTION}` : '')};
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.11px;
  font-weight: bold;
  
  :hover {
    background-color: ${({ color }): string => (
    color === 'primary'
      ? COLORS.COOKIE_PRIMARY_BUTTON_HOVER
      : COLORS.COOKIE_SECONDARY_BUTTON_HOVER
  )};
    color: ${({ color }): string => (color === 'primary' ? '' : `${COLORS.TEXT_PRIMARY}`)};
  }
`;

export default CookieBarButton;
