import { navigate } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledButtonWithArrow from '../ButtonWithArrow';

interface SubmitApplicationButtonProps {
  withArrowBox?: boolean;
  className?: string;
  onClick?: () => void;
}

const SubmitApplicationButton = ({
  className,
  withArrowBox = true,
  onClick = (): void => {},
}: SubmitApplicationButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const onClickHandler = (): void => {
    onClick();
    navigate('/investment');
  };

  return (
    <StyledButtonWithArrow
      className={className}
      color="primary"
      direction="right"
      onClick={onClickHandler}
      title={t('common:button.submit-application')}
      withArrowBox={withArrowBox}
    />
  );
};

export default SubmitApplicationButton;
