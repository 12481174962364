/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';

import { Button, StyledArrowBox } from '@/components';
import { COLORS } from '@/constants';
import { Color, Direction } from '@/interfaces';

interface ButtonWithArrowProps extends Color, Direction {
  className?: string;
  onClick?: () => void;
  title: string;
  withArrowBox?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
}

const ButtonWithArrow = ({
  title,
  className,
  color,
  direction,
  onClick = (): void => {},
  withArrowBox = true,
  loading = false,
  icon,
}: ButtonWithArrowProps): JSX.Element => (
  <div
    className={className}
    onClick={onClick}
  >
    <Button
      className="button"
      color={color}
    >
      { title }
    </Button>
    { withArrowBox && (
      <StyledArrowBox
        className="arrow-box"
        color={color}
        direction={direction}
        icon={icon}
        loading={loading}
      />
    ) }
  </div>
);

const StyledButtonWithArrow = styled(ButtonWithArrow)`
  display: flex;
  cursor: pointer;

  :hover {
    .arrow-box, .button {
      background-color: ${({ color }): string => (
        color === 'primary' ? COLORS.BUTTON_PRIMARY_HOVER : COLORS.BUTTON_SECONDARY_HOVER
      )};
    }
  }

  .arrow-box {
    margin-left: 2px;
  }
`;

export default StyledButtonWithArrow;
