import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Help, ErrorIcon, SuccessIcon } from '@/assets/svgs';
import { Modal } from '@/components';
import { COLORS } from '@/constants';
import { Validation } from '@/interfaces';
import { roundToDecimal } from '@/utils/formatter';

interface InputProps {
  className?: string;
  isNumeric?: boolean;
  placeholder: string;
  name: string;
  unit?: string;
  onChange?: (inputValue: string) => void;
  tooltipMessage?: string;
  validation?: Validation;
  formatter?: (input: string) => string;
  value?: string;
  defaultValue?: string;
  requiredMark?: boolean;
  isMobileScreen: boolean;
}

const Input = ({
  className,
  isNumeric = false,
  formatter,
  onChange = (): void => {},
  placeholder,
  name,
  unit = '',
  tooltipMessage,
  validation,
  value = '',
  defaultValue = '',
  requiredMark = false,
  isMobileScreen,
}: InputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState(value);
  const [isActive, setIsActive] = useState(false);
  const shouldShowValidation = validation && validation.type !== 'NONE';
  const [portalExist, setPortalExist] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { value: changedValue } = event.target;

    if (formatter) {
      setInputValue(formatter(changedValue));
      onChange(formatter(changedValue).replace(/\s/g, ''));
      return;
    }

    if (isNumeric && Number.isNaN(Number(changedValue))) {
      return;
    }

    onChange(changedValue);
    setInputValue(changedValue);
  };

  useEffect(() => {
    if (document && document.getElementById('modal-portal')) {
      setPortalExist(true);
    }
    return (): void => {
      setPortalExist(false);
    };
  }, []);

  useEffect(() => {
    if (isModalVisible && !isMobileScreen) {
      setIsModalVisible(false);
    }
  }, [isModalVisible, isMobileScreen]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnBlur = (): void => {
    setIsActive((previousState) => !previousState);
    setInputValue(roundToDecimal(inputValue));
  };

  const handleOnTooltipClick = (): void => {
    if (!isMobileScreen) {
      return;
    }
    setIsModalVisible(true);
  };

  return (
    <div className={`${className} form-input`}>
      <label
        className={classNames({
          'input-label': true,
          '--active': isActive || inputValue,
          '--error': validation?.type === 'ERROR',
        })}
        htmlFor={name}
      >
        { placeholder }
        { requiredMark && '*' }
      </label>
      <input
        autoComplete="off"
        className={`input ${inputValue ? '--filled' : ''}`}
        defaultValue={defaultValue}
        name={name}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={(): void => setIsActive((previousState) => !previousState)}
        value={inputValue}
      />
      <span className="unit">{ unit }</span>
      <div className="tips">
        { tooltipMessage && (
          <div
            className="tooltip"
            onClick={handleOnTooltipClick}
          >
            <Help />
            { !isMobileScreen && (
              <div className="tooltip-message">
                <div className="tooltip-message-text">
                  { tooltipMessage }
                  { requiredMark && '*' }
                </div>
              </div>
            ) }
          </div>
        ) }
        { shouldShowValidation && (
          <div className="validation">
            { validation && validation.type === 'ERROR'
              ? (
                <>
                  <ErrorIcon />
                  <div className="error-message">{ validation.message }</div>
                </>
              )
              : (<SuccessIcon />) }
          </div>
        ) }
      </div>
      { portalExist && (
        <Modal
          onClose={(): void => setIsModalVisible(false)}
          open={isModalVisible && isMobileScreen}
          text={tooltipMessage || ''}
        />
      ) }
    </div>
  );
};

const StyledInput = styled(Input)`
  position: relative;

  .input-label {
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.FORM.PLACEHOLDER};
    pointer-events: none;
    position: absolute;
    transition: 0.15s;
    top: 0;
    pointer-events: none;

    &.--active {
      font-size: 12px;
      line-height: 14px;
      top: -18px;
      transition: 0.15s;

      @media (max-width: 575px) {
        font-size: 10px;
        line-height: 12px;
      }
    }

    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .unit {
    position: absolute;
    right: 0;
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.FORM.UNIT};

    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .input {
    background: transparent;
    border: none;
    color: ${COLORS.FORM.TEXT};
    caret-color: ${COLORS.FORM.CARET};
    padding-bottom: 14px;
    width: 100%;
    border-bottom: 2px solid ${COLORS.FORM.BORDER};
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 575px) {
      line-height: 20px;
    }

    :-webkit-autofill {
      -webkit-background-clip: text !important;
      -webkit-text-stroke-color: ${COLORS.FORM.TEXT} !important;
      -webkit-text-fill-color: ${COLORS.FORM.TEXT} !important;
    }

    :focus {
      outline: none;
    }

    :focus, &.--filled {
      border-color: ${COLORS.FORM.BORDER_FOCUSED};
    }
  }

  .tips {
    display: flex;
    margin-top: 4px;
    height: 24px;

    .tooltip {
      position: relative;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;

      &:hover {
        #Help {
          fill: ${COLORS.COLOR_SECONDARY};
        }

        .tooltip-message {
          display: initial;
        }
      }
    }

    .tooltip-message {
      position: absolute;
      background-color: ${COLORS.TOOLTIP_BACKGROUND};
      padding: 12px 16px; 
      box-shadow: ${COLORS.SHADOWS.TOOLTIP_MESSAGE};
      border-radius: 4px;
      top: 0;
      left: 27px;
      z-index: 6;
      display: none;
      width: max-content;
      max-width: 340px;
    }

    .tooltip-message-text {
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: ${COLORS.TOOLTIP_TEXT};
    }

    .validation {
      display: flex;
      align-items: center;
    }

    #ErrorIcon {
      min-width: 24px;
    }

    .error-message {
      margin-left: 4px;
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
      color: ${COLORS.ERROR_MESSAGE};
      text-align: left;
    }

    @media (max-width: 900px) {
      .tooltip-message {
        max-width: 300px;
      }
    }
  }
`;

export default StyledInput;
