import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconSelected } from '@/assets/svgs';
import { COLORS } from '@/constants';
import { ProductKind } from '@/interfaces';

interface ProductProps {
  onClick: () => void;
  isSelected: boolean;
  product: ProductKind
}

interface ImageContainerProps {
  isSelected: boolean;
  isAvailable: boolean;
  image: string;
}

const ProductContainer = styled.div`
  cursor: pointer;
`;

const ProductTitle = styled.div`
  margin-top: 16px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Open Sans";
  color: ${COLORS.TEXT_SECONDARY};
  line-height: 1.43;
  text-align: center;
  font-weight: bold;

  &.p-unavailable {
    color: ${COLORS.TEXT_BUTTON_SECONDARY};
  }
`;

const ImageContainer = styled.div<ImageContainerProps>`
  position: relative;
  height: 122px;
  border: ${({ isSelected }): string => (
    isSelected ? `2px solid ${COLORS.COLOR_PRIMARY}` : `2px solid ${COLORS.INVESTMENT_FORM_BACKGROUND}`
  )};

  &:hover {
    border: ${({ isSelected }): string => (
    isSelected ? `2px solid ${COLORS.COLOR_PRIMARY}` : `2px solid ${COLORS.PRODUCT_HOVER_BORDER}`
  )};
  }

  .icon {
    width: 100%;
    height: 118px;
    opacity: ${({ isAvailable }): string => (isAvailable ? '1' : '0.4')};
    background-size: contain;
    background-image: ${({ image }): string => `url('${image}')`};
  }
`;

const CheckboxIcon = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`;

const Product = ({ isSelected, onClick, product }: ProductProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ProductContainer
      className="p-container"
      onClick={onClick}
    >
      <ImageContainer
        className="p-image-container"
        image={product.image}
        isAvailable={product.isAvailable}
        isSelected={isSelected}
      >
        <div className="icon" />
        { isSelected && (
          <CheckboxIcon className="checkbox-selected">
            <IconSelected />
          </CheckboxIcon>
        ) }
      </ImageContainer>
      <ProductTitle
        className={product.isAvailable ? '' : 'p-unavailable'}
      >
        { t(product.label) }
      </ProductTitle>
    </ProductContainer>
  );
};

export default Product;
