import {
  BusinessPremise,
  DeveloperProjects,
  Office,
  Photovoltaics,
  RentalApartments,
  Residence,
  ShoppingMall,
  ShoppingPark,
  Warehouse,
} from '@/assets/images';
import { ProductKind } from '@/interfaces';

const productKinds: Array<ProductKind> = [
  {
    value: 'COMMERCIAL_REAL_ESTATE_WAREHOUSE',
    label: 'investmentForm:product-kinds.warehouses',
    image: Warehouse,
    isAvailable: true,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_OFFICE',
    label: 'investmentForm:product-kinds.office',
    image: Office,
    isAvailable: true,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_BUSINESS_PREMISE',
    label: 'investmentForm:product-kinds.business-premise',
    image: BusinessPremise,
    isAvailable: true,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_SHOPPING_MALL',
    label: 'investmentForm:product-kinds.shopping-mall',
    image: ShoppingMall,
    isAvailable: true,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_RESIDENCE',
    label: 'investmentForm:product-kinds.residence',
    image: Residence,
    isAvailable: false,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_SHOPPING_PARK',
    label: 'investmentForm:product-kinds.shopping-park',
    image: ShoppingPark,
    isAvailable: true,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_PHOTOVOLTAICS',
    label: 'investmentForm:product-kinds.photovoltaics',
    image: Photovoltaics,
    isAvailable: false,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_RENTAL_APARTMENTS',
    label: 'investmentForm:product-kinds.rental-apartments',
    image: RentalApartments,
    isAvailable: false,
  },
  {
    value: 'COMMERCIAL_REAL_ESTATE_DEVELOPER_PROJECTS',
    label: 'investmentForm:product-kinds.developer-projects',
    image: DeveloperProjects,
    isAvailable: false,
  },
];

export default productKinds;
