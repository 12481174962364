/* eslint-disable indent */
import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { validatePolish } from '../../../../node_modules/validate-polish/dist';
import { FormButton, Input, StyledCheckboxWithDescription } from '@/components';
import { COLORS, FORM_RULES } from '@/constants';
import { ConfirmationFormValues, FormData, ProductKind } from '@/interfaces';
import { FormButtonStatuses } from '@/types';
import { replaceCommaWithDot } from '@/utils/formatter';
import isMobile from '@/utils/isMobile';

interface ConfirmationFormContentProps {
  formButtonStatus: FormButtonStatuses;
}

const ConfirmationFormContent = styled.div<ConfirmationFormContentProps>`
  margin-bottom: 40px;
  position: relative;

  > .section-container {
    padding: 120px 138px 118px 138px;
    background-color: ${COLORS.INVESTMENT_FORM_BACKGROUND};

    @media (max-width: 1399px) {
      padding: 100px 138px 115px 138px;
    }

    @media (max-width: 991px) {
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (max-width: 767px) {
      padding: 40px 36px;
    }
  }

  .scroll-ref {
    position: absolute;
    top: -100px;

    @media (max-width: 991px) {
      top: -64px;
    }
  }

  .container {
    @media (max-width: 991px) {
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
    }
  }

  .heading {
    width: 536px;
    max-width: 100%;

    h2 {
      @media (max-width: 767px) {
        margin-bottom: 34px;
      }
    }

    .paragraph {
      font-size: 1rem;
      line-height: 1.5;
      color: ${COLORS.TEXT_SECONDARY};
    }
  }

  &.no-offers {
    .heading {
      @media (max-width: 1399px) {
        width: 470px;
      }

      h2 {
        @media (max-width: 575px) {
          width: 410px;
          max-width: 100%;
        }
      }
    }
  }

  .form {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 260px);
    grid-template-rows: 40px;
    grid-row-gap: 30px;
    grid-column-gap: 154px;

    @media (max-width: 1679px) {
      grid-column-gap: 130px;
    }

    @media (max-width: 1399px) {
      margin-top: 65px;
      grid-template-columns: repeat(3, 230px);
      grid-column-gap: 85px;
    }

    @media (max-width: 1199px) {
      grid-template-columns: repeat(2, 300px);
      grid-column-gap: 65px;
      grid-row-gap: 50px;
    }

    @media (max-width: 767px) {
      display: block;
      margin-top: 40px;
      grid-template-columns: 100%;
    }

    .text {
      font-size: 12px;
      line-height: 18px;
      color: ${COLORS.TOOLTIP_TEXT};

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      @media (max-width: 575px) {
        font-size: 10px;
        line-height: 14px;
      }
    }

    .checkbox-with-description {
      @media (max-width: 1199px) {
        margin-left: 0;
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      .description {
        color: ${COLORS.TOOLTIP_TEXT};

        .agreement {
          &:hover {
            color: ${COLORS.TOOLTIP_TEXT};
          }
        }
      }

      .tooltip {
        margin-left: 80px !important;
      }
    }

    .form-input {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      .input {
        padding-bottom: 8px;
      }
    }

    .checkbox {
      border: 2px solid ${COLORS.SUMMARY_CHECKBOX_BORDER};

      &.error {
        border: 2px solid ${COLORS.FORM.CHECKBOX_ERROR_BORDER};
      }

      #select {
        #select-checkmark {
          fill: ${COLORS.FORM.CHECKBOX_WHITE_BACKGROUND};
          stroke: ${COLORS.FORM.CHECKBOX_WHITE_BACKGROUND};
        }

        #select-background {
          stroke: ${COLORS.FORM.CHECKBOX_VIOLET_CHECKMARK};
          fill: ${COLORS.FORM.CHECKBOX_VIOLET_CHECKMARK};
        }
      }
    }
  }

  .required-text {
    font-size: 12px;
    line-height: 18px;
    color: ${COLORS.TOOLTIP_TEXT};
    margin-top: 34px;

    @media (max-width: 1199px) {
      margin-left: 37px;
    }

    @media (max-width: 767px) {
      margin-top: 16px;
      margin-left: 37px;
    }

    @media (max-width: 575px) {
      font-size: 10px;
      line-height: 14px;
    }
  }

  .send-button {
    margin-top: 100px;
    width: 152px;

    @media (max-width: 1399px) {
      margin-top: 80px;
    }

    @media (max-width: 767px) {
      margin-top: 60px;
    }
    
    .form-button {
      &:hover {
        .button, .arrow-box {
        background-color: ${({ formButtonStatus }): string => (
          formButtonStatus === 'ERROR' ? COLORS.FORM.BUTTON_ERROR : COLORS.BUTTON_SECONDARY_HOVER)
        };
        color: ${COLORS.TEXT_PRIMARY};
        }
      }

      .button, .arrow-box {
        background-color: ${({ formButtonStatus }): string => (
          formButtonStatus === 'ERROR' ? COLORS.FORM.BUTTON_ERROR : COLORS.COLOR_SECONDARY)
        };
        color: ${COLORS.TEXT_PRIMARY};
      }

      .button {
        font-size: 14px;
        line-height: 20px;
      }

      .arrow-box {
        .arrow-icon {
          #arrow {
            stroke: white;
          }
        }
      }
      
      .rcs-roller {
        --rcs-roller-color: white !important;
      }
    }

    .error-label {
      .message {
        color: ${COLORS.TEXT_BUTTON_SECONDARY};
      }
    }
  }
`;

interface ConfirmationFormProps {
  className?: string;
  data?: FormData;
  productKind?: ProductKind;
  title: string;
  subTitle: string;
}

const ConfirmationForm = ({ className, data, title, subTitle, productKind }: ConfirmationFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { control, register, handleSubmit, setValue, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const emptyCheck = t('investmentForm:errors.empty-check');
  const invalidNip = t('investmentForm:errors.invalid-nip');
  const [buttonStatus, setButtonStatus] = useState<FormButtonStatuses>('SUBMIT');
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    register({ name: 'nip' }, {
      required: emptyCheck,
      validate: {
        invalidNip: (value): string | boolean => (
          validatePolish.nip(value) || invalidNip
        ),
      },
    });
    register({ name: 'email' }, {
      required: emptyCheck,
      pattern: {
        value: FORM_RULES.CONTACT_FORM.EMAIL.PATTERN,
        message: t('investmentForm:errors.email-check'),
      },
    });
    register({ name: 'phoneNumber' }, {
      required: emptyCheck,
      maxLength: {
        value: FORM_RULES.CONTACT_FORM.PHONE_NUMBER.MAX_LENGTH,
        message: t('investmentForm:errors.phone-max-length'),
      },
      minLength: {
        value: FORM_RULES.CONTACT_FORM.PHONE_NUMBER.MIN_LENGTH,
        message: t('investmentForm:errors.phone-min-length'),
      },
      pattern: {
        value: FORM_RULES.CONTACT_FORM.PHONE_NUMBER.PATTERN,
        message: t('investmentForm:errors.phone-check'),
      },
    });
    register({ name: 'emailAgree' }, { required: emptyCheck });
    register({ name: 'phoneAgree' }, { required: emptyCheck });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequestURL = (): string => {
    if (data) {
      return `${process.env.GATSBY_REST_API_ENDPOINT}/requests`;
    }
    return `${process.env.GATSBY_REST_API_ENDPOINT}/leads`;
  };

  const handleOnClick = async (formData: ConfirmationFormValues): Promise<void> => {
    setIsLoading(true);

      try {
        await axios.post(getRequestURL(),
          data
            ? {
              nip: formData.nip,
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              productKind: data.productKind,
              status: data.status,
              estatePrice: Number(replaceCommaWithDot(data.estatePrice)),
              area: Number(replaceCommaWithDot(data.area)),
              serviceFee: Number(replaceCommaWithDot(data.serviceFee)),
              yearOfConstruction: Number(data.yearOfConstruction),
              currency: data.currency,
              leaseRate: Number(replaceCommaWithDot(data.leaseRate)),
              localization: data.localization,
            }
            : {
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              emailContactAgreement: formData.emailAgree,
              phoneContactAgreement: formData.phoneAgree,
              leadType: 'UNKNOWN',
              productKind: productKind?.value,
              taxNumber: formData.nip,
            });
        navigate('/success/');
      } catch {
        setButtonStatus('ERROR');
        navigate('/error/');
      } finally {
        setIsLoading(false);
      }
  };

  const setFieldValue = (name: string, value: string | boolean, validate: boolean): void => {
    setValue(name, value, { shouldValidate: validate });
  };

  const handleResize = (): void => {
    setIsMobileScreen(isMobile());
  };

  useEffect(() => {
    handleResize();

    if (window) {
      window.addEventListener('resize', handleResize);
    }
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ConfirmationFormContent
      className={className}
      formButtonStatus={buttonStatus}
    >
      <div
        className="scroll-ref"
        id="confirmationForm-scroll-ref"
      />
      <div className="section-container">
        <div className="container">
          <div className="heading">
            <h2 className="heading--header">{ t(title) }</h2>
            <p>{ t(subTitle) }</p>
          </div>
          <div className="form">
            <Input
              className="form-input"
              isMobileScreen={isMobileScreen}
              isNumeric
              name="nip"
              onChange={(value: string): void => (
                setFieldValue('nip', value, false)
              )}
              placeholder={t('summary:nip')}
              validation={{
                type: errors?.nip ? 'ERROR' : 'NONE',
                message: errors?.nip?.message,
              }}
            />
            <Input
              className="form-input"
              isMobileScreen={isMobileScreen}
              name="email"
              onChange={(value: string): void => (
                setFieldValue('email', value, false)
              )}
              placeholder={t('summary:email')}
              validation={{
                type: errors?.email ? 'ERROR' : 'NONE',
                message: errors?.email?.message,
              }}
            />
            <Input
              className="form-input"
              isMobileScreen={isMobileScreen}
              name="phoneNumber"
              onChange={(value: string): void => (
                setFieldValue('phoneNumber', value, false)
              )}
              placeholder={t('summary:phone-number')}
              validation={{
                type: errors?.phoneNumber ? 'ERROR' : 'NONE',
                message: errors?.phoneNumber?.message,
              }}
            />
            <div className="text">
              <p>{ t('summary:nip-info') }</p>
            </div>
            <Controller
              control={control}
              defaultValue={false}
              name="emailAgree"
              render={({ onChange, value }): JSX.Element => (
                <StyledCheckboxWithDescription
                  agreement={{
                    label: t('contactForm:show-whole'),
                    content: t('contactForm:email-agreement'),
                  }}
                  className="checkbox-with-description"
                  description={t('summary:email-agree')}
                  isMobileScreen={isMobileScreen}
                  onChange={onChange}
                  requiredMark
                  validation={{
                    type: errors.emailAgree ? 'ERROR' : 'NONE',
                    message: errors?.emailAgree?.message,
                  }}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={false}
              name="phoneAgree"
              render={({ onChange, value }): JSX.Element => (
                <StyledCheckboxWithDescription
                  agreement={{
                    label: t('contactForm:show-whole'),
                    content: t('contactForm:phone-agreement'),
                  }}
                  className="checkbox-with-description"
                  description={t('summary:phone-agree')}
                  isMobileScreen={isMobileScreen}
                  onChange={onChange}
                  requiredMark
                  validation={{
                    type: errors.phoneAgree ? 'ERROR' : 'NONE',
                    message: errors?.phoneAgree?.message,
                  }}
                  value={value}
                />
              )}
            />
          </div>
          <div className="required-text">{ t('contactForm:required') }</div>
          <FormButton
            className="send-button"
            loading={isLoading}
            onClick={handleSubmit(handleOnClick)}
            status={buttonStatus}
          />
        </div>
      </div>
    </ConfirmationFormContent>
  );
};

export default ConfirmationForm;
