import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationBarButton from '../NavigationBarButton';
import SubmitApplicationButton from '@/components/Button/SubmitApplicationButton';
import styled from 'styled-components';

export type NAVIGATIONBAR_BUTTON_TYPE = 'FOR_CLIENT' | 'FOR_BANK' | 'CONTACT' | 'LOGIN' | 'NONE' | 'INVESTMENT';

const NAVIGATION_BAR_BUTTONS: { label: string, type: NAVIGATIONBAR_BUTTON_TYPE }[] = [
  {
    label: 'common:navigation-bar.for-client',
    type: 'FOR_CLIENT',
  },
  {
    label: 'common:navigation-bar.for-bank',
    type: 'FOR_BANK',
  },
  {
    label: 'common:navigation-bar.contact',
    type: 'CONTACT',
  },
  {
    label: 'common:navigation-bar.login',
    type: 'LOGIN',
  },
];

interface ButtonsSectionProps {
  className?: string;
  isMobile?: boolean;
  isSubmitVisible?: boolean;
  initialValue: NAVIGATIONBAR_BUTTON_TYPE;
  onClick: (type: NAVIGATIONBAR_BUTTON_TYPE) => void;
}

const ButtonsSection = ({
  className,
  initialValue,
  isSubmitVisible = false,
  onClick,
}: ButtonsSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="buttons-section">
        {
          NAVIGATION_BAR_BUTTONS.map(({ label, type }) => (
            <NavigationBarButton
              key={type}
              active={type === initialValue}
              onClick={(): void => { onClick(type); }}
            >
              { t(label) }
            </NavigationBarButton>
          ))
        }
        { isSubmitVisible && (
          <SubmitApplicationButton
            onClick={(): void => onClick('INVESTMENT')}
            withArrowBox={false}
          />
        ) }
      </div>
    </div>
  );
};

const StyledButtonsSection = styled(ButtonsSection)`
  .buttons-section {
    display: flex;
    place-items: center;
    ${({ isMobile }): string => (isMobile ? 'flex-direction: column;' : '')}

    > * {
      margin-left: 16px;
    }
  }
`;

export default StyledButtonsSection;
